import React from 'react'

import { connect } from 'react-redux'

import { filtersActions, INCOME_LIST_INITIAL_FILTERS } from '@services'

import { usePageConfig } from '@contexts/PageControllerProvider'
import { usePortal } from '@contexts/PortalProvider'

import { INCOME_LIST_DATE_FIELD_OPTIONS } from '@components/pages/constants'

import { ALL_INCOME_SEARCH_FILTERS, DEFAULT_INVOICE_SEARCH_FILTERS } from '@constants'

import { FeaturePermissons, isPlanPermissionEnabled } from '@permissions'

import { FilterBar, FilterBarBaseProps } from './FilterBar'
import { checkFiltersChanged, getSelectableFilterProps, getStatusFilterDialogProps } from './helpers'

import {
  AllFilterOptionsMessage,
  DefaultFilterOptionsMessage,
  IncomeListPageMessage,
  RevenueTypeFilterEmptyPlaceholderMessage,
  RevenueTypeLabelMessage,
  TagFilterEmptyPlaceholderMessage,
  TagFilterLabelMessage,
} from '@messages'

interface PureIncomeListPageFilterBarProps extends FilterBarBaseProps {
  filters: IncomeListStoreFilters
  hasCategorizationPermission: boolean
  hasTagPermission: boolean
  paymentMethodOptions: PaymentMethod[]
  revenueTypeOptions: CommonIdAndNameType[]
  tagOptions: Tag[]
}

function PureIncomeListPageFilterBar({
  revenueTypeOptions,
  filters,
  filters: {
    dateField,
    expiredDays,
    expiringDays,
    isExpired,
    isExpiring,
    isPaid,
    origin,
    paymentMethod,
    search,
    searchFields,
    withRevenueTypeIds,
    withoutRevenueTypeIds,
    withoutTagIds,
    withTagIds,
  },
  hasCategorizationPermission,
  hasTagPermission,
  isDateFilterDisabled,
  paymentMethodOptions,
  resetFilters,
  tagOptions,
  updateFilters,
}: PureIncomeListPageFilterBarProps) {
  const { setPortalAnchorEl } = usePortal()
  const pageConfig = usePageConfig()

  const isDateFilterUsed = true
  const paidStatusFilterProps = {
    values: {
      expiredDays,
      expiringDays,
      isExpired,
      isExpiring,
      isPaid,
    },
  }
  const searchFilterProps = {
    search,
    searchFields,
    options: pageConfig.searchFilterProps?.config.options ?? [],
    extraOptions: pageConfig.searchFilterProps?.config.extraOptions ?? [],
    presets: [
      {
        key: 'defaults',
        optionKeys: DEFAULT_INVOICE_SEARCH_FILTERS,
        label: DefaultFilterOptionsMessage,
      },
      {
        key: 'all',
        optionKeys: ALL_INCOME_SEARCH_FILTERS,
        label: AllFilterOptionsMessage,
      },
    ],
  }

  const selectableFilterProps = getSelectableFilterProps({
    values: {
      withTagIds,
      withRevenueTypeIds,
    },
    config: [
      {
        keyValue: 'withTagIds' as const,
        options: tagOptions,
        labelText: TagFilterLabelMessage,
        emptyPlaceholder: TagFilterEmptyPlaceholderMessage,
        hasPermission: hasTagPermission,
      },
      {
        keyValue: 'withRevenueTypeIds' as const,
        options: revenueTypeOptions,
        labelText: RevenueTypeLabelMessage,
        emptyPlaceholder: RevenueTypeFilterEmptyPlaceholderMessage,
        hasPermission: hasCategorizationPermission,
      },
    ],
  })

  // const statusFilterProps = getStatusFilterProps({
  //   config: INCOME_V2_STATUS_FILTER_CONFIG,
  //   values: filters,
  //   width: 442,
  // })

  const filterDialogProps: FilterDialogProps<StatusFilterConfig> = {
    dateFilterProps: {
      dateTypeOptions: INCOME_LIST_DATE_FIELD_OPTIONS,
      dateTypeValue: dateField,
    },
    isChanged: checkFiltersChanged(filters, INCOME_LIST_INITIAL_FILTERS),
    page: IncomeListPageMessage,
    paidStatusFilterProps,
    searchFilterProps,
  }

  if (pageConfig.statusFilterProps) {
    filterDialogProps['statusFilterProps'] = getStatusFilterDialogProps({
      config: pageConfig.statusFilterProps.config,
      values: filters,
    })
  }
  if (pageConfig.categoryFilterProps) {
    filterDialogProps['categoryFilterProps'] = {
      config: {
        ...pageConfig.categoryFilterProps.config,
        options: revenueTypeOptions,
      },
      values: {
        include: withRevenueTypeIds,
        exclude: withoutRevenueTypeIds,
      },
    }
  }
  if (pageConfig.tagFilterProps) {
    filterDialogProps['tagFilterProps'] = {
      config: {
        ...pageConfig.tagFilterProps.config,
        options: tagOptions,
      },
      values: {
        include: withTagIds,
        exclude: withoutTagIds,
      },
    }
  }
  if (pageConfig.currencyFilterProps) {
    filterDialogProps['currencyFilterProps'] = {
      // config: pageConfig.currencyFilterProps.config,
      currencyId: filters.currencyId, // TODO need to refact: { config: { name: 'currency', options: currencyOptions }, values: filters.currency }
    }
  }
  if (pageConfig.amountFilterProps) {
    filterDialogProps['amountFilterProps'] = {
      // config: pageConfig.amountFilterProps.config,
      grossAmountMax: filters.grossAmountMax,
      grossAmountMin: filters.grossAmountMin,
    }
  }
  if (pageConfig.originFilterProps) {
    filterDialogProps['originFilterProps'] = {
      config: pageConfig.originFilterProps.config,
      values: origin,
    }
  }
  if (pageConfig.paymentMethodFilterProps) {
    filterDialogProps['paymentMethodFilterProps'] = {
      config: {
        ...pageConfig.paymentMethodFilterProps.config,
        options: paymentMethodOptions,
      },
      values: paymentMethod,
    }
  }

  return (
    <FilterBar
      dateField={filters.dateField}
      filterDialogProps={filterDialogProps}
      isDateFilterDisabled={isDateFilterDisabled}
      isDateFilterUsed={isDateFilterUsed}
      paidStatusFilterProps={paidStatusFilterProps}
      resetFilters={resetFilters}
      searchFilterProps={searchFilterProps}
      selectableFilterProps={selectableFilterProps}
      setPortalRef={setPortalAnchorEl}
      updateFilters={updateFilters}
    />
  )
}

export const IncomeListPageFilterBar = connect(
  (state: Store) => {
    const {
      dashboard: {
        common: { payment_methods: paymentMethodOptions },
        tags: { data: tagOptions },
      },
      income: {
        revenueTypes: { data: revenueTypeOptions },
      },
      filters: { incomeList, isGlobalDateFilterDisabled },
    } = state
    return {
      revenueTypeOptions,
      filters: incomeList,
      hasCategorizationPermission: isPlanPermissionEnabled(state, FeaturePermissons.CATEGORIZATION),
      hasTagPermission: isPlanPermissionEnabled(state, FeaturePermissons.TAG),
      isDateFilterDisabled: isGlobalDateFilterDisabled,
      paymentMethodOptions,
      tagOptions,
    }
  },
  {
    resetFilters: filtersActions.resetIncomeListFilters.request,
    updateFilters: filtersActions.updateIncomeListFilters.request,
  }
)(PureIncomeListPageFilterBar)

IncomeListPageFilterBar.displayName = 'IncomeListPageFilterBar'
