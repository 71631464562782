import __intersection from 'lodash/intersection'
import moment from 'moment'
import Qs from 'qs'

import { PaidStatusFilterInnerKeys, PaidStatusFilterKeys } from '@components/filters/PageFilterBars/elements/types'

import {
  CUSTOM_FIELDS_URL_FILTER_SEPARATOR,
  CUSTOM_FIELDS_URL_PARAM_KEY,
  CUSTOM_FIELDS_URL_VALUE_SEPARATOR,
  URL_MAX_LENGTH,
  UrlValidationLevel,
} from '@constants'

import { isWorkerError } from './helpers'
import { SyncFiltersPayload, WorkerRunnerProps } from './types'

export function* parseFiltersFromUrlWorkerRunner({
  config,
  filterOptions: {
    approverOptions,
    categoryTypeOptions,
    currencyOptions,
    documentTypeOptions,
    paidThroughOptions,
    paymentMethodOptions,
    tagOptions,
    vatAreaOptions,
  },
  location,
}: WorkerRunnerProps) {
  const results: SyncFiltersPayload = { filters: {}, params: {}, validationLevel: null }

  // First validation due to 2000 url limit
  const url = location.pathname + location.search
  if (url.length > URL_MAX_LENGTH) {
    results.validationLevel = UrlValidationLevel.TOO_LONG
    // do not try to process
    return results
  }

  // transform params back to snake_case
  const parsedParams = Qs.parse(location.search.substring(1), { comma: true })
  const urlSearchParams = new URLSearchParams(Qs.stringify(parsedParams, { arrayFormat: 'repeat' }))

  // early return when no params
  if (urlSearchParams.size === 0) {
    return results
  }

  let invalidParam = false
  // common API params (ordering, pageSize)
  if (config.orderingProps) {
    if (urlSearchParams.has('ordering')) {
      const param = urlSearchParams.get('ordering')
      if (param) {
        const isDescOrder = param.startsWith('-')
        const value = isDescOrder ? param.substring(1) : param
        if (config.orderingProps.config.options.includes(value)) {
          results.params['order'] = isDescOrder ? 'desc' : 'asc'
          results.params['orderBy'] = value
        } else {
          invalidParam = true
        }
      }
    }
  }
  if (config.paginationProps) {
    if (urlSearchParams.has('pageSize')) {
      const param = urlSearchParams.get('pageSize')
      const value = config.paginationProps.config.options.find(value => String(value) === param)
      if (value) {
        results.params['pageSize'] = value
      } else {
        invalidParam = true
      }
    }
    if (urlSearchParams.has('cursor')) {
      const cursor = urlSearchParams.get('cursor')
      if (cursor) {
        results.params['cursor'] = cursor
      }
    }
  }

  if (config.amountFilterProps) {
    if (urlSearchParams.has('grossAmountMax')) {
      const value = urlSearchParams.get('grossAmountMax')
      if (value) {
        // TODO missing Decimal validation
        results.filters['grossAmountMax'] = value
      }
    }
    if (urlSearchParams.has('grossAmountMin')) {
      const value = urlSearchParams.get('grossAmountMin')
      if (value) {
        // TODO missing Decimal validation
        results.filters['grossAmountMin'] = value
      } else {
        invalidParam = true
      }
    }
  }

  // single choice
  if (config.currencyFilterProps) {
    if (urlSearchParams.has('currencyId')) {
      const currencyId = urlSearchParams.get('currencyId')
      const value = currencyOptions.find(({ id }) => String(id) === currencyId)
      if (value) {
        results.filters['currencyId'] = value
      } else {
        invalidParam = true
      }
    }
  }

  // multi choice
  if (config.originFilterProps) {
    if (urlSearchParams.has(config.originFilterProps.config.name)) {
      const values = __intersection(
        urlSearchParams.getAll(config.originFilterProps.config.name),
        config.originFilterProps.config.options.map(({ value }) => value)
      )
      if (values.length) {
        results.filters[config.originFilterProps.config.name as 'origin'] = values
      } else {
        invalidParam = true
      }
    }
  }
  if (config.paymentMethodFilterProps) {
    if (urlSearchParams.has(config.paymentMethodFilterProps.config.name)) {
      const values = __intersection(
        urlSearchParams.getAll(config.paymentMethodFilterProps.config.name),
        paymentMethodOptions.map(({ value }) => value)
      )
      if (values.length) {
        results.filters[config.paymentMethodFilterProps.config.name as 'paymentMethod'] = values
      } else {
        invalidParam = true
      }
    }
  }
  if (config.vatAreaFilterProps) {
    if (urlSearchParams.has(config.vatAreaFilterProps.config.name)) {
      const values = __intersection(
        urlSearchParams.getAll(config.vatAreaFilterProps.config.name),
        vatAreaOptions.map(({ value }) => value)
      )
      if (values.length) {
        results.filters[config.vatAreaFilterProps.config.name as 'vatArea'] = values
      } else {
        invalidParam = true
      }
    }
  }
  if (config.paidThroughFilterProps) {
    if (urlSearchParams.has(config.paidThroughFilterProps.config.name)) {
      const params = urlSearchParams.getAll(config.paidThroughFilterProps.config.name)
      const values = paidThroughOptions.filter(({ id }) => params.includes(String(id)))
      if (values.length) {
        results.filters[config.paidThroughFilterProps.config.name as 'paidThroughs'] = values
      } else {
        invalidParam = true
      }
    }
  }
  if (config.documentTypeFilterProps) {
    const { includeKey } = config.documentTypeFilterProps.config
    if (includeKey && urlSearchParams.has(includeKey)) {
      // bypass worker here
      const params = urlSearchParams.getAll(includeKey)
      const values = documentTypeOptions.filter(({ id }) => params.includes(String(id)))
      if (values.length) {
        results.filters[includeKey as 'withDocumentTypeIds'] = values
      }
      if (params.length !== values.length) {
        invalidParam = true
      }
    }
  }
  if (config.transactionTypeFilterProps) {
    const { includeKey, options } = config.transactionTypeFilterProps.config
    if (includeKey && urlSearchParams.has(includeKey)) {
      // bypass worker here
      const params = urlSearchParams.getAll(includeKey)
      const values = options.filter(({ id }) => params.includes(String(id)))
      if (values.length) {
        results.filters[includeKey as 'transactionTypes'] = values
      }
      if (params.length !== values.length) {
        invalidParam = true
      }
    }
  }

  // unique
  if (config.dateFilterProps) {
    const { options } = config.dateFilterProps.config
    if (urlSearchParams.has('dateField')) {
      const value = urlSearchParams.get('dateField')
      if (value && options.find(({ key }) => key === value)) {
        results.filters['dateField'] = value
      } else {
        invalidParam = true
      }
    }
    if (urlSearchParams.has('isDateFilterDisabled')) {
      results.filters['isDateFilterDisabled'] = urlSearchParams.get('isDateFilterDisabled') === 'true'
    }
    // only handle when both are provided
    if (urlSearchParams.has('fromDate') && urlSearchParams.has('toDate')) {
      const fromParam = urlSearchParams.get('fromDate')
      const toParam = urlSearchParams.get('toDate')
      if (moment(fromParam, 'YYYY-MM-DD', true).isValid() && moment(toParam, 'YYYY-MM-DD', true).isValid()) {
        results.filters['fromDate'] = fromParam
        results.filters['toDate'] = toParam
      } else {
        invalidParam = true
      }
    }
  }

  if (config.paidStatusFilterProps) {
    //! sync with getPaidStatusFieldConfig helper's response
    if (urlSearchParams.has(PaidStatusFilterKeys.PAID)) {
      const value = urlSearchParams.get(PaidStatusFilterKeys.PAID) === 'true'
      if (value) {
        results.filters[PaidStatusFilterKeys.PAID] = true
      } else {
        invalidParam = true
      }
    }
    if (urlSearchParams.has(PaidStatusFilterKeys.EXPIRING)) {
      const value = urlSearchParams.get(PaidStatusFilterKeys.EXPIRING) === 'true'
      if (value) {
        results.filters[PaidStatusFilterKeys.EXPIRING] = true
      } else {
        invalidParam = true
      }
    }
    if (urlSearchParams.has(PaidStatusFilterKeys.EXPIRED)) {
      const value = urlSearchParams.get(PaidStatusFilterKeys.EXPIRED) === 'true'
      if (value) {
        results.filters[PaidStatusFilterKeys.EXPIRED] = true
      } else {
        invalidParam = true
      }
    }
    if (urlSearchParams.has(PaidStatusFilterInnerKeys.EXPIRING)) {
      const params = urlSearchParams.getAll(PaidStatusFilterInnerKeys.EXPIRING)
      const values = __intersection(['1_7', '8_14', '15_'], params)
      if (values.length) {
        results.filters[PaidStatusFilterInnerKeys.EXPIRING] = values
      } else {
        invalidParam = true
      }
    }
    if (urlSearchParams.has(PaidStatusFilterInnerKeys.EXPIRED)) {
      const params = urlSearchParams.getAll(PaidStatusFilterInnerKeys.EXPIRED)
      const values = __intersection(['0_14', '15_30', '31_'], params)
      if (values.length) {
        results.filters[PaidStatusFilterInnerKeys.EXPIRED] = values
      } else {
        invalidParam = true
      }
    }
  }
  if (config.searchFilterProps) {
    const { options, extraOptions = [] } = config.searchFilterProps.config

    if (urlSearchParams.has('searchFields')) {
      const allAvailableOptions = [...options, ...extraOptions].map(({ key }) => key)
      const params = urlSearchParams.getAll('searchFields')
      const values = __intersection(params, allAvailableOptions)
      if (values.length) {
        results.filters['searchFields'] = values
      } else {
        invalidParam = true
      }
    }
    if (urlSearchParams.has('search')) {
      const value = urlSearchParams.get('search')
      if (value) {
        results.filters['search'] = value
      } else {
        invalidParam = true
      }
    }
  }
  if (config.statusFilterProps) {
    const statusFilterConfig = config.statusFilterProps.config
    statusFilterConfig.forEach(({ name, options }) => {
      if (urlSearchParams.has(name)) {
        const option = options.find(({ value }) => String(value) === urlSearchParams.get(name))
        if (option) {
          results.filters[name] = option.value
        } else {
          invalidParam = true
        }
      }
    })
  }

  //* process data with webWorker
  if (config.tagFilterProps || config.categoryFilterProps) {
    // Initialize the web worker
    const worker = new Worker(new URL('@webWorkers/filterOptions.worker.ts', import.meta.url))

    if (config.tagFilterProps) {
      const { includeKey, excludeKey, hasPermission } = config.tagFilterProps.config
      if (includeKey && urlSearchParams.has(includeKey)) {
        if (hasPermission) {
          //* Send data to the worker
          const params = urlSearchParams.getAll(includeKey)
          worker.postMessage({ params, options: tagOptions })
          // Listen for messages from the worker
          const values: Tag[] | WorkerError = yield new Promise(resolve => {
            worker.onmessage = event => resolve(event.data)
          })
          if (isWorkerError(values)) {
            throw new Error(`WebWorker error: ${values.error}`)
          }
          if (values.length) {
            results.filters[includeKey as 'withTagIds'] = values
          }
          if (params.length !== values.length) {
            invalidParam = true
          }
        } else {
          invalidParam = true
        }
      }
      if (excludeKey && urlSearchParams.has(excludeKey)) {
        if (hasPermission) {
          //* Send data to the worker
          const params = urlSearchParams.getAll(excludeKey)
          worker.postMessage({ params, options: tagOptions })
          // Listen for messages from the worker
          const values: Tag[] | WorkerError = yield new Promise(resolve => {
            worker.onmessage = event => resolve(event.data)
          })
          if (isWorkerError(values)) {
            throw new Error(`WebWorker error: ${values.error}`)
          }
          if (values.length) {
            results.filters[excludeKey as 'withoutTagIds'] = values
          }
          if (params.length !== values.length) {
            invalidParam = true
          }
        } else {
          invalidParam = true
        }
      }
    }

    if (config.categoryFilterProps) {
      const { includeKey, excludeKey, hasPermission } = config.categoryFilterProps.config
      if (includeKey && urlSearchParams.has(includeKey)) {
        if (hasPermission) {
          //* Send data to the worker
          const params = urlSearchParams.getAll(includeKey)
          worker.postMessage({ params, options: categoryTypeOptions })
          // Listen for messages from the worker
          const values: CommonIdAndNameType[] | WorkerError = yield new Promise(resolve => {
            worker.onmessage = event => resolve(event.data)
          })
          if (isWorkerError(values)) {
            throw new Error(`WebWorker error: ${values.error}`)
          }
          if (values.length) {
            results.filters[includeKey as 'withTagIds'] = values
          }
          if (params.length !== values.length) {
            invalidParam = true
          }
        } else {
          invalidParam = true
        }
      }
      if (excludeKey && urlSearchParams.has(excludeKey)) {
        if (hasPermission) {
          //* Send data to the worker
          const params = urlSearchParams.getAll(excludeKey)
          worker.postMessage({ params, options: categoryTypeOptions })
          // Listen for messages from the worker
          const values: CommonIdAndNameType[] | WorkerError = yield new Promise(resolve => {
            worker.onmessage = event => resolve(event.data)
          })
          if (isWorkerError(values)) {
            throw new Error(`WebWorker error: ${values.error}`)
          }
          if (values.length) {
            results.filters[excludeKey as 'withoutTagIds'] = values
          }
          if (params.length !== values.length) {
            invalidParam = true
          }
        } else {
          invalidParam = true
        }
      }
    }

    worker.onmessage = null
    // Close the worker after processing
    worker.terminate()
  }

  if (config.approversFilterProps) {
    const { includeKey, excludeKey } = config.approversFilterProps.config
    if (includeKey && urlSearchParams.has(includeKey)) {
      const params = urlSearchParams.getAll(includeKey)
      const values = approverOptions.filter(({ id }) => params.includes(String(id)))
      if (values.length) {
        results.filters[includeKey as 'withApprovers'] = values
      }
      if (params.length !== values.length) {
        invalidParam = true
      }
    }
    if (excludeKey && urlSearchParams.has(excludeKey)) {
      const params = urlSearchParams.getAll(excludeKey)
      const values = approverOptions.filter(({ id }) => params.includes(String(id)))
      if (values.length) {
        results.filters[excludeKey as 'withoutApprovers'] = values
      }
      if (params.length !== values.length) {
        invalidParam = true
      }
    }
  }

  //* CUSTOM FIELDS
  if (config.customFieldsFilterProps) {
    const { fields } = config.customFieldsFilterProps.config
    if (urlSearchParams.has(CUSTOM_FIELDS_URL_PARAM_KEY)) {
      const params = urlSearchParams.get(CUSTOM_FIELDS_URL_PARAM_KEY)
      if (params) {
        const customFieldsParams = params.split(CUSTOM_FIELDS_URL_FILTER_SEPARATOR).map(field => {
          const [key, value] = field.split(CUSTOM_FIELDS_URL_VALUE_SEPARATOR)
          return { key, value }
        })
        // validate params keys and values against the config
        const customFields = customFieldsParams.filter(({ key, value }) => {
          const fieldConfig = fields.find(({ fieldName }) => fieldName === key)
          if (fieldConfig) {
            const validOption = fieldConfig.options.find(option => option.value === value)
            return !!validOption
          }
          return false
        })
        if (customFields.length) {
          results.filters['customFields'] = customFields
        }
        if (customFieldsParams.length !== customFields.length) {
          invalidParam = true
        }
      }
    }
  }

  if (invalidParam) {
    results.validationLevel = UrlValidationLevel.INVALID_PARAMS
  }

  return results
}
