import {
  FilenameSearchMessage,
  FilingNumberSearchMessage,
  InvoiceNumberSearchMessage,
  KeywordsSearchMessage,
  PartnerNameSearchMessage,
  RecipientSearchMessage,
  SecondaryIdSearchMessage,
  SenderSearchMessage,
  SummarySearchMessage,
  TitleSearchMessage,
} from '@messages'

export const INITIAL_ORIGIN_FILTER: string[] = []

export const INITIAL_PAYMENT_METHOD_FILTER: string[] = []

export const INITIAL_VAT_AREA_FILTER: string[] = []

export const INITIAL_PAID_THROUGH_FILTER: CommonIdAndNameType[] = []

export const INITIAL_PAID_STATUS_FILTERS: PaidStatusFilterValues = {
  expiredDays: [],
  expiringDays: [],
  isExpired: false,
  isExpiring: false,
  isPaid: false,
}

// quarantine expense, income
export const DEFAULT_INVOICE_SEARCH_FILTERS = ['partner_name', 'invoice_number', 'secondary_id']

export const ALL_INCOME_SEARCH_FILTERS = [...DEFAULT_INVOICE_SEARCH_FILTERS, 'income_item_name', 'post_it_note']

// extended expense list
export const DEFAULT_EXPENSE_SEARCH_FILTERS = [...DEFAULT_INVOICE_SEARCH_FILTERS, 'filing_number']

export const ALL_EXPENSE_SEARCH_FILTERS = [
  ...DEFAULT_INVOICE_SEARCH_FILTERS,
  'invoice_item_name',
  'post_it_note',
  'filing_number',
  'ledger_number', // only when isAdvancedAccountingAvailableForUser
  'job_number', // only when isAdvancedAccountingAvailableForUser
]

export const EMPTY_CUSTOM_FIELD_OPTION_VALUE = '' // this is a select option's value and can not be null

export const CUSTOM_FIELDS_URL_PARAM_KEY = 'cf' as const

export const CUSTOM_FIELDS_URL_VALUE_SEPARATOR = ':'

export const CUSTOM_FIELDS_URL_FILTER_SEPARATOR = '|'

//* List controls
export const EXPENSE_DEFAULT_SEARCH_FIELD_OPTIONS = [
  {
    key: 'partner_name',
    label: PartnerNameSearchMessage,
  },
  {
    key: 'invoice_number',
    label: InvoiceNumberSearchMessage,
  },
  {
    key: 'filing_number',
    label: FilingNumberSearchMessage,
  },
  {
    key: 'secondary_id',
    label: SecondaryIdSearchMessage,
  },
]

export const INCOME_DEFAULT_SEARCH_FIELD_OPTIONS = [
  {
    key: 'partner_name',
    label: PartnerNameSearchMessage,
  },
  {
    key: 'invoice_number',
    label: InvoiceNumberSearchMessage,
  },
  {
    key: 'secondary_id',
    label: SecondaryIdSearchMessage,
  },
]

export const DOKUMENTS_DEFAULT_SEARCH_FIELD_OPTIONS = [
  {
    key: 'original_filename',
    label: FilenameSearchMessage,
  },
  {
    key: 'title',
    label: TitleSearchMessage,
  },
  {
    key: 'summary',
    label: SummarySearchMessage,
  },
  {
    key: 'keywords',
    label: KeywordsSearchMessage,
  },
  {
    key: 'sender',
    label: SenderSearchMessage,
  },
  {
    key: 'recipient',
    label: RecipientSearchMessage,
  },
  {
    key: 'filing_number',
    label: FilingNumberSearchMessage,
  },
]
