import React from 'react'

import { FormattedDate } from 'react-intl'
import { connect } from 'react-redux'

import { getRouteUrl } from '@helpers'

import { PreviewVariant } from '@contexts/PreviewProvider'

import { InvoiceArtifactPreviewOpener, InvoicePreviewOpener } from '@components/DocViewer'
import { SimpleTable } from '@components/tables'
import { CopyAndPasteButton, Currency, OverflowText, TooLongText } from '@components/ui'
import { ReactHookFieldArray, ReactHookFormAmountTableField } from '@components/ui/FormElements'

import { ALL_PAYMENT_TRANSACTION_TYPES, PAYMENT_FLOW, RouteKeys } from '@constants'

import { AccountNumberWarning } from './AccountNumberWarning'
import { BalanceTransactionButton } from './BalanceTransactionButton'
import { TableAccountNumberField } from './fields'
import { isInvoiceTransactionType } from './helpers'
import { CreateBankPaymentTableAccessors, CreateBankPaymentTableRow, getTableColumns } from './PaymentTableElements'
import { useSecondaryIdVisible } from './useSecondaryIdVisible'

function getRowId(row: CreateBankPaymentTableRow) {
  return row.rowData.transactionId
}

interface PaymentTransactionsFieldArrayTableProps {
  companyId: number
  disabled?: boolean
  flow: PaymentFlowType
  transactions: PaymentTransaction[]
  transactionType: AllPaymentTransactionType
}

function PurePaymentTransactionsFieldArrayTable({
  companyId,
  disabled = false,
  flow,
  transactions,
  transactionType,
}: PaymentTransactionsFieldArrayTableProps) {
  // there is no secodanry_id in incomes yet
  const isSecondaryIdVisible = useSecondaryIdVisible(transactionType, transactions)

  const tableColumns = React.useMemo(() => {
    return getTableColumns({
      isSecondaryIdVisible,
      isSummary: false,
      isTransferFlow: flow === PAYMENT_FLOW.transfer,
      transactionType,
    })
  }, [flow, isSecondaryIdVisible, transactionType])

  const useTableProps = React.useMemo(() => {
    const hiddenColumns = [CreateBankPaymentTableAccessors.ROW_DATA]

    if (PAYMENT_FLOW.transfer !== flow) {
      hiddenColumns.push(CreateBankPaymentTableAccessors.PARTNER_ACCOUNT_NUMBER)
    }
    if (!isSecondaryIdVisible) {
      hiddenColumns.push(CreateBankPaymentTableAccessors.SECONDARY_ID)
    }

    return {
      initialState: {
        hiddenColumns,
      },
      getRowId,
    }
  }, [flow, isSecondaryIdVisible])

  const isIncome = transactionType === ALL_PAYMENT_TRANSACTION_TYPES.income

  return (
    <ReactHookFieldArray
      name="transactions"
      render={({ fields }) => (
        <SimpleTable
          columns={tableColumns}
          data={fields.map((field, index) => {
            const transaction = transactions[index]
            const detailsUrl = `${getRouteUrl(isIncome ? RouteKeys.INCOME : RouteKeys.COST, companyId)}/${
              transaction.transactionId
            }`
            return {
              [CreateBankPaymentTableAccessors.ROW_DATA]: transaction,
              [CreateBankPaymentTableAccessors.PREVIEW]: (
                <>
                  {transaction.artifact && (
                    <InvoiceArtifactPreviewOpener
                      detailsUrl={detailsUrl}
                      fileName={transaction.artifact.filename}
                      path={transaction.artifact.path}
                      previewVariant={PreviewVariant.INVOICE}
                    />
                  )}
                  {!transaction.artifact && transaction.links.preview && (
                    <InvoicePreviewOpener
                      detailsUrl={detailsUrl}
                      invoiceId={transaction.transactionId}
                      invoiceVariant={isIncome ? 'income' : 'expense'}
                      previewUrl={transaction.links.preview}
                      previewVariant={PreviewVariant.INVOICE}
                    />
                  )}
                </>
              ),
              [CreateBankPaymentTableAccessors.PARTNER]: <OverflowText>{transaction.partnerName}</OverflowText>,
              [CreateBankPaymentTableAccessors.TITLE]: transaction.title && (
                <>
                  {isInvoiceTransactionType(transactionType) ? (
                    <CopyAndPasteButton text={transaction.title} disabled={disabled}>
                      <TooLongText value={transaction.title} disablePortal maxLength={15} />
                    </CopyAndPasteButton>
                  ) : (
                    <FormattedDate value={transaction.title} year="numeric" month="long" />
                  )}
                </>
              ),
              [CreateBankPaymentTableAccessors.SECONDARY_ID]: transaction.secondaryId && (
                <CopyAndPasteButton text={transaction.secondaryId} disabled={disabled}>
                  <TooLongText value={transaction.secondaryId} disablePortal maxLength={15} />
                </CopyAndPasteButton>
              ),
              [CreateBankPaymentTableAccessors.PARTNER_ACCOUNT_NUMBER]: (
                <AccountNumberWarning fieldIndex={index} partnerAccountNumber={transaction.partnerAccountNumber}>
                  <TableAccountNumberField
                    key={`account_number-${field.id}`}
                    name={`transactions[${index}].accountNumber`}
                  />
                </AccountNumberWarning>
              ),
              [CreateBankPaymentTableAccessors.DUE_AT]: <FormattedDate value={transaction.dueAt} />,
              [CreateBankPaymentTableAccessors.GROSS_AMOUNT]: (
                <Currency value={Number(transaction.grossAmount)} currency={transaction.currencyName} />
              ),
              [CreateBankPaymentTableAccessors.REMAINING]: (
                <Currency value={Number(transaction.remaining)} currency={transaction.currencyName} />
              ),
              [CreateBankPaymentTableAccessors.AMOUNT]: (
                <>
                  <ReactHookFormAmountTableField
                    key={`amount-${field.id}`}
                    currency={transaction.currencyName}
                    name={`transactions[${index}].amount`}
                  />
                  <BalanceTransactionButton
                    fieldName={`transactions[${index}].amount`}
                    amount={(Number(transaction.amount) || 0) + Number(transaction.remaining)}
                  />
                </>
              ),
            }
          })}
          layout="fixed"
          useTableProps={useTableProps}
        />
      )}
    />
  )
}

export const PaymentTransactionsFieldArrayTable = connect((state: Store) => ({
  companyId: state.auth.company.data.id,
}))(PurePaymentTransactionsFieldArrayTable)

PaymentTransactionsFieldArrayTable.displayName = 'PaymentTransactionsFieldArrayTable'
