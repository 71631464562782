import { checkTokenExpiraiton, getToken } from '../helpers'
import actions from './actions'

const checkTokenMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const result = next(action)

    const {
      auth: { isLoggedIn, renew_token_inprogress, token_expired },
    } = getState()
    if (isLoggedIn && !renew_token_inprogress) {
      const auth_token = getToken()

      if (auth_token) {
        checkTokenExpiraiton(dispatch, auth_token)
      } else if (!token_expired) {
        dispatch(actions.expiredToken.request())
      }
    }

    return result
  }

export default checkTokenMiddleware
