import React from 'react'

import __isEqual from 'lodash/isEqual'
import { connect } from 'react-redux'

import { BANK_TRANSACTIONS_INITIAL_FILTERS, filtersActions } from '@services'

import { usePageConfig } from '@contexts/PageControllerProvider'

import { BANK_TRANSACTIONS_DATE_FIELD_OPTIONS } from '@components/pages/constants'

import { FilterBar, FilterBarBaseProps } from './FilterBar'
import { getSelectableFilterProps } from './helpers'

import {
  BankTransactionsListPageMessage,
  PaidThroughsFilterEmptyPlaceholderMessage,
  PaidThroughsFilterLabelMessage,
  PaidThroughsFilterSelectedLabelMessage,
} from '@messages'

interface PureBankTransactionsPageFilterBarProps extends FilterBarBaseProps {
  filters: BankTransactionsStoreFilters
  paidThroughOptions: PaidThroughOption[]
}

function PureBankTransactionsPageFilterBar({
  filters,
  filters: { paidThroughs, search, searchFields, dateField },
  isDateFilterDisabled,
  paidThroughOptions,
  resetFilters,
  updateFilters,
}: PureBankTransactionsPageFilterBarProps) {
  const pageConfig = usePageConfig()

  const isDateFilterUsed = true
  const searchFilterProps = {
    search,
    searchFields,
    options: pageConfig.searchFilterProps?.config.options ?? [],
  }
  const selectableFilterProps = getSelectableFilterProps({
    values: {
      paidThroughs,
    },
    config: [
      {
        keyValue: 'paidThroughs' as const,
        options: paidThroughOptions,
        labelText: PaidThroughsFilterLabelMessage,
        selectedLabelText: PaidThroughsFilterSelectedLabelMessage,
        emptyPlaceholder: PaidThroughsFilterEmptyPlaceholderMessage,
      },
    ],
  })

  const filterDialogProps: FilterDialogProps<StatusFilterConfig> = {
    dateFilterProps: {
      dateTypeOptions: BANK_TRANSACTIONS_DATE_FIELD_OPTIONS,
      dateTypeValue: dateField,
    },
    isChanged: !__isEqual(filters, BANK_TRANSACTIONS_INITIAL_FILTERS),
    page: BankTransactionsListPageMessage,
    searchFilterProps,
  }

  if (pageConfig.currencyFilterProps) {
    filterDialogProps['currencyFilterProps'] = {
      // config: pageConfig.currencyFilterProps.config,
      currencyId: filters.currencyId, // TODO need to refact: { config: { name: 'currency', options: currencyOptions }, values: filters.currency }
    }
  }
  if (pageConfig.paidThroughFilterProps) {
    filterDialogProps['paidThroughFilterProps'] = {
      config: {
        ...pageConfig.paidThroughFilterProps.config,
        options: paidThroughOptions,
      },
      values: paidThroughs,
    }
  }
  if (pageConfig.amountFilterProps) {
    filterDialogProps['amountFilterProps'] = {
      // config: pageConfig.amountFilterProps.config,
      grossAmountMax: filters.grossAmountMax,
      grossAmountMin: filters.grossAmountMin,
    }
  }

  return (
    <FilterBar
      dateField={dateField}
      filterDialogProps={filterDialogProps}
      isDateFilterDisabled={isDateFilterDisabled}
      isDateFilterUsed={isDateFilterUsed}
      resetFilters={resetFilters}
      searchFilterProps={searchFilterProps}
      selectableFilterProps={selectableFilterProps}
      updateFilters={updateFilters}
    />
  )
}

export const BankTransactionsPageFilterBar = connect(
  ({ payment: { paidThroughOptions }, filters: { bankTransactions, isGlobalDateFilterDisabled } }: Store) => {
    return {
      filters: bankTransactions,
      isDateFilterDisabled: isGlobalDateFilterDisabled,
      paidThroughOptions,
    }
  },
  {
    updateFilters: filtersActions.updateBankTransactionsFilters.request,
    resetFilters: filtersActions.resetBankTransactionsFilters.request,
  }
)(PureBankTransactionsPageFilterBar)

BankTransactionsPageFilterBar.displayName = 'BankTransactionsPageFilterBar'
