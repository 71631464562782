import React from 'react'

import __partition from 'lodash/partition'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { CreatePaymentFormData, CreatePaymentPayload } from '@contexts/PaymentProvider'

import {
  Button,
  InfoIcon,
  ReactHookForm,
  ReactHookFormCheckboxField,
  ReactHookFormDatePickerField,
  ReactHookFormError,
  ReactHookFormSubmitButton,
} from '@components/ui'
import { LightTooltip } from '@oldComponents/ui'

import { EXPRESS_PAID_THROUGH_NAME, PAYMENT_TRANSACTION_TYPE } from '@constants'

import { CreatePaymentFormProps } from '../types'
import { BalanceAllTransactionsButton } from './BalanceAllTransactionsButton'
import { BinxInfoMessage } from './BinxInfoMessage'
import { PaidThroughField, PaidThroughSelectOption } from './fields'
import { getToday } from './helpers'
import { PaidThroughBalance } from './PaidThroughBalance'
import { PaymentFooter } from './PaymentFormElements'
import { PaymentInformationalMessage } from './PaymentInformationalMessage'
import { PaymentNotificationEmailsFieldArray } from './PaymentNotificationEmailsFieldArray'
import { PaymentTransactionsFieldArrayTable } from './PaymentTransactionsFieldArrayTable'
import { TransactionsTotalAmount } from './TransactionsTotalAmount'
import { TransferPaymentHeader } from './TransferPaymentHeader'
import { useTransformPaymentFormValidationSchema } from './validators'

import { CancelButtonMessage, CreateButtonMessage } from '@messages'
import { FormDialogBody } from '@components/FormDialogs/styles'
import { DatePickerLabelMessage } from './messages'
import { PaymentDialogActions, PaymentDialogBodyWrapper, SameSizeButtonsContainer } from './styles'

type PaymentFormInitialValues = Pick<
  CreatePaymentFormData,
  'date' | 'transactions' | 'notificationEmails' | 'showAlert'
> &
  Partial<Pick<CreatePaymentFormData, 'paidThroughId'>>

function useInitialValues(
  data: PaymentSetupResponseData,
  companyDefaultPaidThroughId: Nullable<number>,
  paidThroughOptions: PaidThroughOption[]
) {
  return React.useMemo<PaymentFormInitialValues>(() => {
    const { notificationEmails, transactions } = data

    let paidThroughId = undefined
    // only prefill with companyDefaultPaidThroughId if it has "bank" type and exits in paidThroughOptions
    const defaultOption = paidThroughOptions.find(({ id }) => id === companyDefaultPaidThroughId)
    if (defaultOption) {
      paidThroughId = defaultOption.id
    } else if (paidThroughOptions.length === 1) {
      paidThroughId = paidThroughOptions[0].id
    }

    return {
      date: getToday(),
      notificationEmails: (notificationEmails || []).map(({ emails, partnerId }) => ({
        emails: emails.join('; '),
        partnerId,
      })),
      paidThroughId: paidThroughId,
      showAlert: false,
      transactions: transactions.map(({ beneficiaryAccountNumber, transactionId }) => ({
        amount: '',
        accountNumber: beneficiaryAccountNumber || '',
        transactionId: transactionId,
      })),
    }
  }, [companyDefaultPaidThroughId, data, paidThroughOptions])
}

export function PureCreateTransferPaymentForm({
  companyDefaultPaidThrough,
  data,
  data: { transactions, notificationEmails },
  flow,
  onClose,
  onSubmit,
  onSubmitSuccess,
  paidThroughOptions,
  showInfoRow,
  transactionType,
}: CreatePaymentFormProps) {
  const validationSchema = useTransformPaymentFormValidationSchema()
  const initialValues = useInitialValues(data, companyDefaultPaidThrough, paidThroughOptions)

  function onSubmitHandler({ showAlert, notificationEmails = [], ...values }: CreatePaymentFormData) {
    const payload: CreatePaymentPayload = {
      ...values,
      transactionType,
      flow,
      paidThroughType: 2, // bank only
    }

    if (showAlert) {
      payload.notificationEmails = notificationEmails.map(({ partnerId, emails }) => ({
        partnerId,
        emails: emails.split(/[,;\s]+/).filter(o => o),
      }))
    }

    return onSubmit(payload)
  }

  const paidThroughCustomOptions = React.useMemo<PaidThroughSelectOption[]>(() => {
    const options = paidThroughOptions.map(option => ({
      ...option,
      isDefault: option.id === companyDefaultPaidThrough,
    }))
    // making 3 groups of options - separete items but keep the order
    const [def, others] = __partition(options, ({ id }) => id === companyDefaultPaidThrough)
    const [downloads, rest] = __partition(others, ({ provider }) => Boolean(provider))
    // merge the groups
    return [...def, ...downloads, ...rest]
  }, [companyDefaultPaidThrough, paidThroughOptions])

  return (
    <ReactHookForm
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      onSubmitSuccess={onSubmitSuccess}
      validationSchema={validationSchema}
    >
      <FormDialogBody>
        <PaymentDialogBodyWrapper>
          {showInfoRow && <PaymentInformationalMessage transactionType={transactionType} />}
          <TransferPaymentHeader
            button={<BalanceAllTransactionsButton transactions={transactions} />}
            datepicker={<ReactHookFormDatePickerField name="date" label={DatePickerLabelMessage} required />}
            paidThroughSelect={<PaidThroughField options={paidThroughCustomOptions} />}
            paidThroughBalance={<PaidThroughBalance />}
          />
          <BinxInfoMessage />
          <PaymentTransactionsFieldArrayTable
            flow={flow}
            transactions={transactions}
            transactionType={transactionType}
          />
          <PaymentFooter
            checkbox={
              transactionType === PAYMENT_TRANSACTION_TYPE.expense ? (
                <ReactHookFormCheckboxField
                  name="showAlert"
                  label={
                    <FormattedMessage
                      id="paymentDialog.alertNotifications"
                      defaultMessage="Értesítés küldése a partnereknek az utalási csomag elkészültéről {info}"
                      values={{
                        info: (
                          <LightTooltip
                            placement="top"
                            title={
                              <FormattedMessage
                                id="paymentDialog.alertNotifications.infoTooltip"
                                defaultMessage="Minden partnerhez megadhatsz akár több e-mailcímet is, vesszővel elválasztva. A kiküldött e-mailekről másolatot fogsz kapni."
                              />
                            }
                            PopperProps={{ disablePortal: true }}
                          >
                            <span>
                              <InfoIcon size={14} />
                            </span>
                          </LightTooltip>
                        ),
                      }}
                    />
                  }
                />
              ) : (
                <></>
              )
            }
            totalAmount={<TransactionsTotalAmount transactions={transactions} />}
          />
          <PaymentNotificationEmailsFieldArray notificationEmails={notificationEmails || []} />
        </PaymentDialogBodyWrapper>
        <PaymentDialogActions borderless>
          <ReactHookFormError />
          <SameSizeButtonsContainer>
            <Button onClick={onClose} variant="secondaryContained" type="button">
              {CancelButtonMessage}
            </Button>
            <ReactHookFormSubmitButton buttonText={CreateButtonMessage} isCreateOnly />
          </SameSizeButtonsContainer>
        </PaymentDialogActions>
      </FormDialogBody>
    </ReactHookForm>
  )
}

export const CreateTransferPaymentForm = connect((state: Store) => ({
  companyDefaultPaidThrough: state.auth.company.data.default_paid_through,
  paidThroughOptions: state.payment.paidThroughOptions.filter(
    ({ name, paidthrough_type }) => paidthrough_type === 2 && name !== EXPRESS_PAID_THROUGH_NAME
  ), // only bank type allowed here
}))(PureCreateTransferPaymentForm)

CreateTransferPaymentForm.displayName = 'CreateTransferPaymentForm'
