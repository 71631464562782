import React from 'react'
import PropTypes from 'prop-types'

import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import styled from 'styled-components'

import { ButtonProps, TextButtonWithIcon } from '@components/ui'

const StyledButton = styled(TextButtonWithIcon)`
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.gray[40] : theme.colors.common.navBadge)};
`

export function AssignmentsBreakdownButton({ children, onClick, ...rest }: ButtonProps) {
  return (
    <StyledButton {...rest} startIcon={<PlaylistAddIcon fontSize="small" />} onClick={onClick}>
      {children}
    </StyledButton>
  )
}

AssignmentsBreakdownButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
}
