import React from 'react'

import styled from 'styled-components'

import { PreviewVariant } from '@contexts/PreviewProvider'

import { CircleArrowRegularIcon, LinkIconButton } from '@components/ui'
import { LightTooltip } from '@oldComponents/ui'

import { ArtifactPreviewOpener } from './ArtifactPreviewOpener'

import { GoToLinkMessage } from '@messages'

const StyledLinkButton = styled(LinkIconButton)`
  padding: 4px;
`

function preventClickPropagation(event: React.MouseEvent) {
  event.stopPropagation()
}

interface DokumentPreviewOpenerProps {
  detailsUrl: string
  data: DokumentListData
}

export function DokumentPreviewOpener({ detailsUrl, data }: DokumentPreviewOpenerProps) {
  if (data.link) {
    return (
      <LightTooltip title={GoToLinkMessage} PopperProps={{ disablePortal: true }}>
        <StyledLinkButton href={data.link} size="small" onClick={preventClickPropagation}>
          <CircleArrowRegularIcon size={18} />
        </StyledLinkButton>
      </LightTooltip>
    )
  }

  if (data.path && data.filename) {
    return (
      <ArtifactPreviewOpener
        detailsUrl={detailsUrl}
        fileName={data.filename}
        path={data.path}
        previewVariant={PreviewVariant.DOKUMENT}
        title={data.title}
      />
    )
  }
  return null
}
