import React from 'react'

import { Grid } from '@material-ui/core'
import { useFieldArray } from 'react-hook-form'
import styled from 'styled-components'

import { FormBlock, FormBlockTitle } from '@components/ui'

import { DynamicField } from './DynamicField'

import { ExpenseCustomFieldsHeadingMessage } from '@messages'
import { SPACING } from '@oldComponents/forms/DetailsForm/styles'

const StyledFormBlock = styled(FormBlock)`
  margin-top: 0.5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[110]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[110]};
`

interface ExpenseCustomFieldsProps {
  customFieldsConfig: Nullable<ExpenseCustomFieldConfig[]>
  disabled: boolean
}

export function ExpenseCustomFields({ customFieldsConfig, disabled }: ExpenseCustomFieldsProps) {
  const { fields } = useFieldArray<ExpenseDetailsFrontendValues, 'custom_fields'>({
    name: 'custom_fields',
  })

  // when no custom fields are defined, we don't render anything
  if (fields.length === 0 || !customFieldsConfig) {
    return null
  }

  return (
    <StyledFormBlock data-testid="expense-custom-fields">
      <FormBlockTitle>{ExpenseCustomFieldsHeadingMessage}</FormBlockTitle>
      {fields
        .map((field, index) => (
          <Grid container item xs={12} spacing={SPACING} key={`custom-fields-container-${index}`}>
            <Grid item xs={6} key={field.id}>
              <DynamicField
                config={
                  customFieldsConfig.find(
                    ({ field_name }) => field.field_name === field_name
                  ) as ExpenseCustomFieldConfig
                }
                disabled={disabled}
                name={`custom_fields[${index}].value` as const}
              />
            </Grid>
            {fields[index + 1] && (
              <Grid item xs={6} key={fields[index + 1].id}>
                <DynamicField
                  config={
                    customFieldsConfig.find(
                      ({ field_name }) => fields[index + 1].field_name === field_name
                    ) as ExpenseCustomFieldConfig
                  }
                  disabled={disabled}
                  name={`custom_fields[${index + 1}].value` as const}
                />
              </Grid>
            )}
          </Grid>
        ))
        // layout: 2 fields per row (to keep it in a single map)
        .filter((_, index) => index % 2 === 0)}
    </StyledFormBlock>
  )
}
