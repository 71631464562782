import React from 'react'

import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'

import {
  Button,
  CustomDialog,
  CustomDialogActions,
  CustomDialogBody,
  CustomDialogHeader,
  ReactHookForm,
  ReactHookFormError,
  ReactHookFormSubmitButton,
  Typography,
} from '@components/ui'

import { CloseButtonMessage } from '@messages'

const StyledCustomDialogActions = styled(CustomDialogActions)`
  flex-direction: column;
  align-items: center;
`

interface SuccessResponseViewProps {
  responseText: React.ReactNode
  children: React.ReactNode
  onClose: VoidFunction
}

function SuccessResponseView({ responseText, children, onClose }: SuccessResponseViewProps) {
  const {
    formState: { isSubmitSuccessful },
  } = useFormContext()

  if (isSubmitSuccessful) {
    return (
      <>
        <CustomDialogBody>
          <Typography align="center" color="gray-80">
            {responseText}
          </Typography>
        </CustomDialogBody>
        <CustomDialogActions borderless>
          <Button variant="primaryContained" onClick={onClose}>
            {CloseButtonMessage}
          </Button>
        </CustomDialogActions>
      </>
    )
  }
  return <>{children}</>
}

interface SimpleFormDialogProps<FormValues extends object, Results> {
  children: React.ReactNode
  className?: string
  initialValues: FormValues
  onClose: VoidFunction
  onSubmit: AsyncFunction<any, Results>
  onSubmitSuccess: (results: Results) => void
  onSubmitSuccessText: React.ReactNode
  open: boolean
  title: React.ReactChild
  validationSchema: yup.SchemaOf<FormValues>
}

export function SimpleFormDialog<FormValues extends object, Results>({
  children,
  className,
  initialValues,
  onClose,
  onSubmit,
  onSubmitSuccess,
  onSubmitSuccessText,
  open,
  title,
  validationSchema,
}: SimpleFormDialogProps<FormValues, Results>) {
  return (
    <CustomDialog className={className} open={open} onClose={onClose}>
      <CustomDialogHeader borderless title={title} />
      <ReactHookForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
        skipResetAfterSuccessfulSubmit
        validationSchema={validationSchema}
      >
        <SuccessResponseView responseText={onSubmitSuccessText} onClose={onClose}>
          <CustomDialogBody>{children}</CustomDialogBody>
          <StyledCustomDialogActions borderless>
            <ReactHookFormError />
            <ReactHookFormSubmitButton isCreateOnly />
          </StyledCustomDialogActions>
        </SuccessResponseView>
      </ReactHookForm>
    </CustomDialog>
  )
}
