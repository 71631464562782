import React from 'react'

import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { GroupBase, SelectInstance } from 'react-select'

import { cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch } from '@contexts/AlertProvider'

import { useCancellablePromiseRef } from '@hooks'

import { ReactHookFormSelectField } from '@components/ui'
import { ReactHookFormSelectFieldProps } from '@components/ui/FormElements/ReactHookFormSelectField'

import { InvoiceType } from '@constants'

import { formSelectMessages } from '@messages'
import messages from '@oldComponents/forms/DetailsForm/messages'

interface AssignmentCategoryFieldProps
  extends Omit<
    ReactHookFormSelectFieldProps<CommonIdAndNameType>,
    | 'isClearable'
    | 'isLoading'
    | 'label'
    | 'onCreate'
    | 'onEmptyCreateText'
    | 'skipSorting'
    | 'name'
    | 'labelKey'
    | 'valueKey'
  > {
  assignmentPrefix: `assignments.${number}.`
  assignmentType: string
  company: number
  invoiceType: InvoiceType
  isCreateDeniedForUser: boolean
  onCreateCategoryType: CreateCommonIdAndNameTypeCallback
}

function PureAssignmentCategoryField({
  assignmentPrefix,
  assignmentType,
  company,
  invoiceType,
  isCreateDeniedForUser,
  onCreateCategoryType,
  ...rest
}: AssignmentCategoryFieldProps) {
  const { formatMessage } = useIntl()
  const cPromiseRef = useCancellablePromiseRef<CommonIdAndNameType>()
  const { setErrorAlert } = useAlertDispatch()
  const [isLoading, setIsLoading] = React.useState(false)

  const onCreateHandler = React.useCallback(
    async (
      newOption: { name: string },
      selectRef: SelectInstance<CommonIdAndNameType, boolean, GroupBase<CommonIdAndNameType>>
    ) => {
      setIsLoading(true)
      try {
        cPromiseRef.current = cancellablePromise(onCreateCategoryType({ name: newOption.name, company }))
        const { id, name } = await cPromiseRef.current.promise

        // select it
        selectRef.selectOption({ id, name })
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          setErrorAlert(errorMessage)
        }
      }
      setIsLoading(false)
    },
    [cPromiseRef, company, onCreateCategoryType, setErrorAlert]
  )

  return (
    <ReactHookFormSelectField
      {...rest}
      isClearable
      isLoading={isLoading}
      label={formatMessage(messages.itemCostTypeLabel)}
      name={`${assignmentPrefix}${invoiceType}_type`}
      skipSorting
      {...(!isCreateDeniedForUser && {
        onCreate: onCreateHandler,
        onEmptyCreateText: formatMessage(formSelectMessages.typeEmptyCreateText, {
          type: assignmentType.toLowerCase(),
        }),
      })}
    />
  )
}

export const AssignmentCategoryField = connect((state: Store) => ({
  company: state.auth.company.data.id,
}))(PureAssignmentCategoryField)

AssignmentCategoryField.displayName = 'AssignmentCategoryField'
