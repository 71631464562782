import React from 'react'

import { connect } from 'react-redux'

import { filtersActions } from '@services'

import { usePageConfig } from '@contexts/PageControllerProvider'

import { FilterBar, FilterBarBaseProps } from './FilterBar'
import { getSelectableFilterProps } from './helpers'

import {
  PaidThroughsFilterEmptyPlaceholderMessage,
  PaidThroughsFilterLabelMessage,
  PaidThroughsFilterSelectedLabelMessage,
} from '@messages'

interface PaymentOrdersPageFilterBarBaseProps extends FilterBarBaseProps {
  filters: PaymentOrdersStoreFilters
  paidThroughOptions: PaidThroughOption[]
}

function PurePaymentOrdersPageFilterBar({
  filters: { paidThroughs, search, searchFields, dateField },
  isDateFilterDisabled,
  paidThroughOptions,
  resetFilters,
  updateFilters,
}: PaymentOrdersPageFilterBarBaseProps) {
  const pageConfig = usePageConfig()

  const isDateFilterUsed = true
  const searchFilterProps = {
    search,
    searchFields,
    options: pageConfig.searchFilterProps?.config.options ?? [],
  }
  const selectableFilterProps = getSelectableFilterProps({
    values: {
      paidThroughs,
    },
    config: [
      {
        keyValue: 'paidThroughs' as const,
        options: paidThroughOptions,
        labelText: PaidThroughsFilterLabelMessage,
        selectedLabelText: PaidThroughsFilterSelectedLabelMessage,
        emptyPlaceholder: PaidThroughsFilterEmptyPlaceholderMessage,
      },
    ],
  })

  return (
    <FilterBar
      dateField={dateField}
      isDateFilterDisabled={isDateFilterDisabled}
      isDateFilterUsed={isDateFilterUsed}
      resetFilters={resetFilters}
      searchFilterProps={searchFilterProps}
      selectableFilterProps={selectableFilterProps}
      updateFilters={updateFilters}
    />
  )
}

export const PaymentOrdersPageFilterBar = connect(
  ({ payment: { paidThroughOptions }, filters: { isGlobalDateFilterDisabled, paymentOrders } }: Store) => {
    return {
      filters: paymentOrders,
      isDateFilterDisabled: isGlobalDateFilterDisabled,
      paidThroughOptions,
    }
  },
  {
    resetFilters: filtersActions.resetPaymentOrderFilters.request,
    updateFilters: filtersActions.updatePaymentOrderFilters.request,
  }
)(PurePaymentOrdersPageFilterBar)
PaymentOrdersPageFilterBar.displayName = 'PaymentOrdersPageFilterBar'
