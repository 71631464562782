import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import styled, { css } from 'styled-components'

import { userHasEditorPermission } from '@helpers'

import { Paper } from '@components/ui'

const StyledPaper = styled(Paper)`
  ${({ disableStyles }) =>
    !disableStyles &&
    css`
      margin-left: -26px;
      margin-right: -26px;
      margin-top: 16px;
      padding: 16px 24px;
    `}
`

interface ContentWithHighlightBlockProps {
  additionalStyle?: React.CSSProperties
  children: React.ReactNode
  isEditorUser: boolean
  needHighlight: boolean
}

function PureContentWithHighlightBlock({
  additionalStyle,
  children,
  isEditorUser,
  needHighlight,
}: ContentWithHighlightBlockProps) {
  const disableStyles = !(isEditorUser && needHighlight)

  return (
    <StyledPaper disableStyles={!(isEditorUser && needHighlight)} {...(!disableStyles && { style: additionalStyle })}>
      {children}
    </StyledPaper>
  )
}

PureContentWithHighlightBlock.propTypes = {
  additionalStyle: PropTypes.object as React.Validator<React.CSSProperties | undefined>,
  children: PropTypes.node.isRequired,
  isEditorUser: PropTypes.bool.isRequired,
  needHighlight: PropTypes.bool.isRequired,
}

export const ContentWithHighlightBlock = connect((state: Store) => ({
  isEditorUser: userHasEditorPermission(state.auth.company.data.role),
}))(PureContentWithHighlightBlock)

ContentWithHighlightBlock.displayName = 'ContentWithHighlightBlock'
