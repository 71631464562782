import React from 'react'
import PropTypes from 'prop-types'

import { useIntl } from 'react-intl'

import { usePortal } from '@contexts/PortalProvider'

import { SummaryCustomDialogActions, SupportView } from '@components/pages/PulsePage/TaxationTasks/elements'
import { Button, CustomDialogBody } from '@components/ui'

import { CloseButtonMessage } from '@messages'
import { supportViewCommentGenericPlaceholderMessage } from '@components/pages/PulsePage/TaxationTasks/messages'

interface ClosureSummaryViewProps {
  supportLink: string
  onEnterResponseState: VoidFunction
  onClose: VoidFunction
}

export function ClosureSummaryView({ supportLink, onClose, onEnterResponseState }: ClosureSummaryViewProps) {
  const { portalAnchorEl, setPortalAnchorEl } = usePortal()
  const { formatMessage } = useIntl()
  return (
    <>
      <CustomDialogBody>
        <SupportView
          onEnterResponseState={onEnterResponseState}
          placeholder={formatMessage(supportViewCommentGenericPlaceholderMessage)}
          portalAnchorEl={portalAnchorEl}
          url={supportLink}
        />
      </CustomDialogBody>
      <SummaryCustomDialogActions>
        <div ref={setPortalAnchorEl} />
        <Button variant="primaryText" onClick={onClose}>
          {CloseButtonMessage}
        </Button>
      </SummaryCustomDialogActions>
    </>
  )
}

ClosureSummaryView.propTypes = {
  supportLink: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onEnterResponseState: PropTypes.func.isRequired,
}
