import React from 'react'

import { cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch } from '@contexts/AlertProvider'

import { useCancellablePromiseRef } from '@hooks/useCancellablePromiseRef'

import { BackendReportData, BackendReportResults, FetchReportsHookProps } from './types'

interface UseFetchReportsState {
  error: BackendError
  initializing: boolean
  loading: boolean
  loadMoreUrl: BackendCallableLink
  reports: BackendReportData[]
  updatedAt: string
}

export function useFetchReports({ callUrl, fetchReports }: FetchReportsHookProps) {
  const mountedRef = React.useRef(true)
  const { setErrorAlert } = useAlertDispatch()
  const cPromiseRef = useCancellablePromiseRef<BackendReportResults>()
  const [{ reports, initializing, loading, loadMoreUrl, error, updatedAt }, setState] =
    React.useState<UseFetchReportsState>({
      error: null,
      initializing: true,
      loading: false,
      loadMoreUrl: null,
      reports: [],
      updatedAt: '',
    })

  const loadMoreHandler = React.useCallback(async () => {
    if (loadMoreUrl) {
      setState(prevState => ({ ...prevState, loading: true }))
      try {
        cPromiseRef.current = cancellablePromise(
          callUrl({
            method: 'get',
            url: loadMoreUrl,
          })
        )
        const results = await cPromiseRef.current.promise
        setState(prevState => ({
          ...prevState,
          loading: false,
          loadMoreUrl: results.links.more_reports,
          reports: [...prevState.reports, ...results.reports],
        }))
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          setErrorAlert(errorMessage)
          setState(prevState => ({ ...prevState, loading: false }))
        }
      }
    }
  }, [cPromiseRef, callUrl, loadMoreUrl, setErrorAlert])

  const fetchReportsHandler = React.useCallback(async () => {
    if (!mountedRef.current) {
      return
    }
    try {
      cPromiseRef.current = cancellablePromise(fetchReports())
      const results = await cPromiseRef.current.promise
      setState(prevState => ({
        ...prevState,
        initializing: false,
        loadMoreUrl: results.links.more_reports,
        reports: results.reports,
        updatedAt: new Date().toISOString(),
      }))
    } catch (error) {
      const errorMsg = parseApiErrorMessage(error)
      if (errorMsg) {
        setState(prevState => ({ ...prevState, initializing: false, error: errorMsg }))
      }
    }
  }, [cPromiseRef, fetchReports])

  React.useEffect(() => {
    fetchReportsHandler()

    return () => {
      mountedRef.current = false
    }
  }, [fetchReportsHandler])

  return {
    error,
    hasMore: Boolean(loadMoreUrl),
    initializing,
    loading,
    loadMore: loadMoreHandler,
    refetch: fetchReportsHandler,
    reports,
    updatedAt,
  }
}
