import React from 'react'
import PropTypes from 'prop-types'

import { NavigateFunction, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { ButtonBase, FilterResetIcon, OldIconButton, Paper } from '@components/ui'
import { LightTooltip } from '@oldComponents/ui'

import { FILTER_DIALOG_FILTER_PROPS_PROP_TYPE } from '@constants'

import {
  ActiveFilters,
  ClampedTypography,
  FilterDialogButton,
  FilterSelect,
  PaidStatusFilter,
  StatusFilter,
  WidgetSearchFilter,
} from './elements'
import { StickyFilterBar } from './StickyFilterBar'

import { ClearFiltersButtonMessage } from '@messages'

const FiltersContainer = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  min-width: 0;
  line-height: 1.4;
  > * {
    max-width: 450px;
  }
`

const ButtonsContainer = styled.div<{ $isCompact: boolean }>`
  display: flex;
  height: 100%;
  background-color: inherit;
  z-index: 1;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  margin: ${({ $isCompact }) => ($isCompact ? '0 -21px 0 21px' : 0)};

  & > * {
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    padding: 15px 10px;
    flex-shrink: 0;
  }

  ${ButtonBase} {
    &:not(:last-of-type) {
      border-radius: unset;
    }
    &:last-of-type {
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
    }
  }
`

const FiltersPaper = styled(Paper)<{ $isCompact: boolean }>`
  margin-bottom: ${({ $isCompact }) => ($isCompact ? 0 : 10)}px;
  height: ${({ $isCompact }) => ($isCompact ? 79 : 80)}px;
  display: flex;
  padding: ${({ $isCompact }) => ($isCompact ? '0 21px' : 0)};
  align-items: center;

  & > *:not(:first-child),
  & > * > *:not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
  }

  ${({ $isCompact }) =>
    $isCompact &&
    css`
      border-radius: unset;
      border-top: none;
      border-left: none;
      border-right: none;
    `}
`

const StyledStatusFilter = styled(StatusFilter)<{ $width: number }>`
  width: ${({ $width }) => `${$width}px`};
  min-width: ${({ $width }) => `${$width}px`};
`

const ActiveFiltersWrapperDiv = styled.div<{ $isCompact: boolean }>`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  ${({ $isCompact, theme }) =>
    $isCompact &&
    css`
      height: ${theme.appBarHeightMedium}px;
      background-color: ${theme.colors.gray[0]};
      padding: 0 20px;
      margin-bottom: 0;

      ${ClampedTypography} {
        -webkit-line-clamp: 1;
      }
    `}
`

const ResetIcon = styled(FilterResetIcon)`
  color: ${({ theme }) => theme.colors.error[150]};
`

export interface FilterBarBaseProps {
  isDateFilterDisabled: boolean
  resetFilters: (payload: { navigate: NavigateFunction }) => void
  updateFilters: (payload: { filters: UpdateFiltersPayload; navigate: NavigateFunction }) => void
}
export interface FilterBarProps<
  CustomStatusFilterBarConfig extends StatusFilterConfig = never,
  CustomStatusFilterDialogConfig extends StatusFilterConfig = never
> extends FilterBarBaseProps {
  className?: string
  dateField?: string
  filterDialogProps?: FilterDialogProps<CustomStatusFilterDialogConfig>
  isDateFilterUsed?: boolean
  isDateFilterToggleDisabled?: boolean
  paidStatusFilterProps?: PaidStatusFilterProps
  searchFilterProps?: SearchFilterProps
  selectableFilterProps?: SelectableFilterProps
  setPortalRef?: (payload: HTMLDivElement) => void
  statusFilterProps?: StatusFilterBarProps<CustomStatusFilterBarConfig>
  syncSearchInputValue?: (payload: string) => void
}

export function FilterBar<
  CustomStatusFilterBarConfig extends StatusFilterConfig = never,
  CustomStatusFilterDialogConfig extends StatusFilterConfig = never
>({
  className,
  dateField,
  filterDialogProps,
  isDateFilterDisabled,
  isDateFilterToggleDisabled = false,
  isDateFilterUsed = true,
  paidStatusFilterProps,
  resetFilters,
  searchFilterProps,
  selectableFilterProps,
  setPortalRef,
  statusFilterProps,
  syncSearchInputValue,
  updateFilters,
}: FilterBarProps<CustomStatusFilterBarConfig, CustomStatusFilterDialogConfig>) {
  const navigate = useNavigate()

  const onResetHandler: VoidFunction = React.useCallback(() => {
    resetFilters({ navigate })
  }, [navigate, resetFilters])

  const onChangeHandler: UpdateFiltersFunction = React.useCallback(
    filters => {
      updateFilters({ filters, navigate })
    },
    [navigate, updateFilters]
  )

  const isActiveFiltersVisible = Boolean(selectableFilterProps?.config.length) || isDateFilterUsed

  function handleReset() {
    onResetHandler() // do not pass onClick event as payload
  }

  return (
    <StickyFilterBar>
      {sticky => (
        <aside className={className}>
          <FiltersPaper $isCompact={sticky}>
            <FiltersContainer>
              {searchFilterProps && (
                <WidgetSearchFilter
                  onChange={onChangeHandler}
                  onInputValueChange={syncSearchInputValue}
                  search={searchFilterProps.search}
                  searchFieldExtraOptions={searchFilterProps.extraOptions}
                  searchFieldOptions={searchFilterProps.options}
                  searchFieldPresets={searchFilterProps.presets}
                  searchFields={searchFilterProps.searchFields}
                />
              )}

              {statusFilterProps != null && (
                <StyledStatusFilter
                  $width={statusFilterProps.width}
                  config={statusFilterProps.config}
                  value={statusFilterProps.values}
                  onChange={onChangeHandler}
                />
              )}

              {paidStatusFilterProps != null && (
                <PaidStatusFilter value={paidStatusFilterProps.values} onChange={onChangeHandler} />
              )}

              {selectableFilterProps?.config.map(filterConfig => (
                <FilterSelect
                  key={filterConfig.keyValue}
                  value={selectableFilterProps.values[filterConfig.keyValue] ?? []}
                  onChange={onChangeHandler}
                  {...filterConfig}
                />
              ))}
            </FiltersContainer>
            <ButtonsContainer $isCompact={sticky}>
              {filterDialogProps && (
                <FilterDialogButton
                  filterProps={filterDialogProps}
                  isDateFilterDisabled={isDateFilterDisabled}
                  isDateFilterToggleDisabled={isDateFilterToggleDisabled}
                  updateFilters={onChangeHandler}
                  resetFilters={onResetHandler}
                  syncSearchInputValue={syncSearchInputValue}
                />
              )}
              <LightTooltip title={ClearFiltersButtonMessage}>
                <OldIconButton onClick={handleReset}>
                  <ResetIcon />
                </OldIconButton>
              </LightTooltip>
            </ButtonsContainer>
          </FiltersPaper>
          <ActiveFiltersWrapperDiv $isCompact={sticky}>
            {isActiveFiltersVisible && (
              <ActiveFilters
                amountFilterProps={filterDialogProps?.amountFilterProps}
                approversFilterProps={filterDialogProps?.approversFilterProps}
                categoryFilterProps={filterDialogProps?.categoryFilterProps}
                currencyFilterProps={filterDialogProps?.currencyFilterProps}
                customFieldsFilterProps={filterDialogProps?.customFieldsFilterProps}
                dateField={dateField}
                isDateFilterDisabled={isDateFilterDisabled}
                originFilterProps={filterDialogProps?.originFilterProps}
                paidStatusFilterProps={filterDialogProps?.paidStatusFilterProps ?? paidStatusFilterProps}
                paidThroughFilterProps={filterDialogProps?.paidThroughFilterProps}
                paymentMethodFilterProps={filterDialogProps?.paymentMethodFilterProps}
                selectableFilterProps={filterDialogProps ? undefined : selectableFilterProps} // only when no filterDialog
                statusFilterProps={filterDialogProps?.statusFilterProps ?? statusFilterProps}
                tagFilterProps={filterDialogProps?.tagFilterProps}
                vatAreaFilterProps={filterDialogProps?.vatAreaFilterProps}
              />
            )}
            {/* NOTE portal for eg.: collapsable div control button */}
            {!sticky && setPortalRef && <div ref={setPortalRef} />}
          </ActiveFiltersWrapperDiv>
        </aside>
      )}
    </StickyFilterBar>
  )
}

FilterBar.propTypes = {
  className: PropTypes.string,
  dateField: PropTypes.string,
  filterDialogProps: FILTER_DIALOG_FILTER_PROPS_PROP_TYPE,
  isCompact: PropTypes.bool,
  isDateFilterDisabled: PropTypes.bool.isRequired,
  isDateFilterUsed: PropTypes.bool,
  isDateFilterToggleDisabled: PropTypes.bool,
  paidStatusFilterProps: PropTypes.shape({
    values: PropTypes.shape({
      expiredDays: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      expiringDays: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      isExpired: PropTypes.bool.isRequired,
      isExpiring: PropTypes.bool.isRequired,
      isPaid: PropTypes.bool.isRequired,
    }).isRequired,
  }) as React.Validator<PaidStatusFilterProps>,
  resetFilters: PropTypes.func.isRequired,
  searchFilterProps: PropTypes.shape({
    search: PropTypes.string.isRequired,
    searchFields: PropTypes.arrayOf(PropTypes.string.isRequired),
  }) as React.Validator<SearchFilterProps>,
  selectableFilterProps: PropTypes.shape({
    values: PropTypes.object.isRequired,
    config: PropTypes.array.isRequired,
  }) as React.Validator<SelectableFilterProps>,
  setPortalRef: PropTypes.func,
  statusFilterProps: PropTypes.shape({
    config: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.bool,
            label: PropTypes.node.isRequired,
          }).isRequired
        ).isRequired,
      }).isRequired
    ),
    values: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
  }),
  updateFilters: PropTypes.func.isRequired,
}
