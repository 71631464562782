import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { BulkFilingDialog } from '@components/dialogs'
import { AsyncButtonWithIcon, FlexRow, InfoText, Paper, Typography, WalletPlusIcon } from '@components/ui'

import { FilingButtonMessage } from '@messages'

const Wrapper = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;
`

export interface DetailsFilingProps {
  children: React.ReactNode
  dialogInfoMessage: React.ReactNode
  disabled?: boolean
  handleClose: VoidFunction
  handleFiling: VoidFunction
  handleOpen: VoidFunction
  isActionAvailable: boolean
  loading: boolean
  open: boolean
}

export function DetailsFiling({
  children,
  dialogInfoMessage,
  disabled = false,
  handleClose,
  handleFiling,
  handleOpen,
  isActionAvailable,
  loading,
  open,
}: DetailsFilingProps) {
  return (
    <Wrapper>
      <Typography size="400-sm" color="inherit">
        {children}
      </Typography>
      {isActionAvailable && (
        <>
          <AsyncButtonWithIcon
            data-testid="details-filing-button"
            disabled={disabled}
            icon={<WalletPlusIcon size={24} />}
            loading={loading}
            onClick={handleOpen}
            size="small"
            variant="primaryText"
          >
            {FilingButtonMessage}
          </AsyncButtonWithIcon>
          <BulkFilingDialog open={open} onClose={handleClose} handleFiling={handleFiling}>
            <Paper infoBox>
              <InfoText iconColor="gray-40" color="gray-80" size="400-md">
                {dialogInfoMessage}
              </InfoText>
            </Paper>
          </BulkFilingDialog>
        </>
      )}
    </Wrapper>
  )
}

DetailsFiling.propTypes = {
  children: PropTypes.node.isRequired,
  dialogInfoMessage: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleFiling: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  isActionAvailable: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
}
