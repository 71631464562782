import React from 'react'

import { InputLabel } from '@material-ui/core'
import { defineMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { Typography } from '@components/ui'
import { SelectInput } from '@components/ui/FormElements'

import { ExpenseCustomFieldsHeadingMessage } from '@messages'
import { FilterWrapper } from '../styles'

const PlaceholderMessage = defineMessage({
  id: 'dialogs.filters.customFields.select.placeholder',
  defaultMessage: 'Minden érték',
})

const FiltersContainer = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`

interface CustomFieldsFiltersProps {
  config: CustomFieldsFilterConfig
  onChange: (values: CustomFieldsFilter[]) => void
  values: CustomFieldsFilter[]
}

export function CustomFieldsFilters({ config: { fields }, onChange, values }: CustomFieldsFiltersProps) {
  const { formatMessage } = useIntl()

  const valueMap = React.useMemo(
    () =>
      values.reduce((acc, filter) => {
        acc[filter.key] = filter.value
        return acc
      }, {} as Record<string, string>),
    [values]
  )

  const handleChange = React.useCallback(
    (name: string) => (selectValue: Nullable<string>) => {
      // always keep the order of the fields
      const newValues = fields
        .map(({ fieldName }) => ({
          key: fieldName,
          value: fieldName === name ? selectValue : valueMap[fieldName],
        }))
        .filter((item): item is CustomFieldsFilter => item.value != null) // select cleared - filter out cleared value

      onChange(newValues)
    },
    [fields, onChange, valueMap]
  )

  const getValue = React.useCallback((name: string) => values.find(v => v.key === name)?.value ?? null, [values])

  return (
    <FilterWrapper data-testid="custom-fields-filter-wrapper">
      <Typography size="700-xs" color="gray-80">
        {ExpenseCustomFieldsHeadingMessage}
      </Typography>
      <FiltersContainer>
        {fields.map(({ fieldName, label, options }) => (
          <div key={`custom_field_filter_${fieldName}`}>
            <InputLabel htmlFor={fieldName} shrink>
              {label}
            </InputLabel>
            <SelectInput
              isClearable
              labelKey="label"
              menuShouldBlockScroll
              name={fieldName}
              onChange={handleChange(fieldName)}
              options={options}
              placeholder={formatMessage(PlaceholderMessage)}
              value={getValue(fieldName)}
              valueKey="value"
            />
          </div>
        ))}
      </FiltersContainer>
    </FilterWrapper>
  )
}
