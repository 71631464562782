import React from 'react'

import { FieldPath, useFormContext } from 'react-hook-form'

import { calculateGrossAndVatAmount, calculateNetAndVatAmount, calculateVatAmount } from '@helpers'

import { useCalculationBaseControl } from '@contexts/CalculationBaseControlProvider'

type FieldKey = FieldPath<IncomeDetailsFrontendValues>

export function useChangeHandlers(companyVats: CompanyVatType[], isAdvancedAccounting: boolean) {
  const { getValues, setValue } = useFormContext<IncomeDetailsFrontendValues>()
  const { calculationBase } = useCalculationBaseControl()

  return React.useMemo(() => {
    //* NET_AMOUNT field change hadler
    function onChangeAssignmentNetAmountHandler(field: string, value: string) {
      const fieldKey: FieldKey = 'net_amount'
      const currency = getValues('currency')

      if (calculationBase === 'net_amount') {
        const percent =
          companyVats.find(({ id }) => id === getValues(field.replace(fieldKey, 'vat') as `assignments.${number}.vat`))
            ?.percent ?? null
        const amounts = calculateGrossAndVatAmount(value, percent, currency)

        setValue(field.replace(fieldKey, 'vat_amount') as FieldKey, amounts.vat_amount)
        setValue(field.replace(fieldKey, 'gross_amount') as FieldKey, amounts.gross_amount)
      } else {
        // calculte vat_amount
        const grossAmount =
          getValues(field.replace(fieldKey, 'gross_amount') as `assignments.${number}.gross_amount`) || 0
        const vatAmount = calculateVatAmount(grossAmount, value, currency)

        setValue(field.replace(fieldKey, 'vat_amount') as FieldKey, vatAmount)
      }
    }

    //* GROSS_AMOUNT field change hadler
    function onChangeAssignmentGrossAmountHandler(field: string, value: string) {
      const fieldKey = 'gross_amount'
      const currency = getValues('currency')

      if (calculationBase === 'net_amount') {
        // calculte vat_amount
        const netAmount = getValues(field.replace(fieldKey, 'net_amount') as `assignments.${number}.net_amount`) || 0
        const vatAmount = calculateVatAmount(value, netAmount, currency)

        setValue(field.replace(fieldKey, 'vat_amount') as FieldKey, vatAmount)
      } else {
        const percent =
          companyVats.find(({ id }) => id === getValues(field.replace(fieldKey, 'vat') as `assignments.${number}.vat`))
            ?.percent ?? null
        const amounts = calculateNetAndVatAmount(value, percent, currency)

        setValue(field.replace(fieldKey, 'vat_amount') as FieldKey, amounts.vat_amount)
        setValue(field.replace(fieldKey, 'net_amount') as FieldKey, amounts.net_amount)
      }
    }

    //* VAT field change hadler
    function onChangeAssignmentVatAmountHandler(
      field: string,
      value: IncomeDetailsFrontendValues['assignments'][number]['vat']
    ) {
      const fieldKey = 'vat'
      const currency = getValues('currency')
      const percent = companyVats.find(({ id }) => id === value)?.percent ?? null

      if (calculationBase === 'net_amount') {
        const netAmount = getValues(field.replace(fieldKey, 'net_amount') as `assignments.${number}.net_amount`) || 0
        const amounts = calculateGrossAndVatAmount(netAmount, percent, currency)

        setValue(field.replace(fieldKey, 'vat_amount') as FieldKey, amounts.vat_amount)
        setValue(field.replace(fieldKey, 'gross_amount') as FieldKey, amounts.gross_amount)
      } else {
        const grossAmount =
          getValues(field.replace(fieldKey, 'gross_amount') as `assignments.${number}.gross_amount`) || 0
        const amounts = calculateNetAndVatAmount(grossAmount, percent, currency)

        setValue(field.replace(fieldKey, 'vat_amount') as FieldKey, amounts.vat_amount)
        setValue(field.replace(fieldKey, 'net_amount') as FieldKey, amounts.net_amount)
      }

      // clear company_vat_category field value
      if (isAdvancedAccounting) {
        setValue(field.replace(fieldKey, 'company_vat_category_id') as FieldKey, null)
        setValue(field.replace(fieldKey, 'company_vat_category_code') as FieldKey, null)
        setValue(field.replace(fieldKey, 'company_vat_category_name') as FieldKey, null)
      }
    }

    return {
      onChangeAssignmentNetAmountHandler,
      onChangeAssignmentGrossAmountHandler,
      onChangeAssignmentVatAmountHandler,
    }
  }, [calculationBase, companyVats, getValues, isAdvancedAccounting, setValue])
}
