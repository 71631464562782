import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import OverflowTextWithTooltip from '@oldComponents/OverflowTextWithTooltip'
import { LightTooltip, LinkIcon } from '@oldComponents/ui'

import Badge, { BadgeVariant } from './Badge'

interface ContainerDivProps {
  maxWidth: number
}

// TODO later it should be re-create OverflowTextWithTooltip with styled component
const StyledOverflowText = styled(OverflowTextWithTooltip)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const ContainerDiv = styled.div<ContainerDivProps>`
  display: flex;
  align-items: center;
  max-width: ${props => props.maxWidth}px;
  gap: 2px;

  ${StyledOverflowText} {
    margin-right: 8px; // so the sum of margin is 8+2=10
  }
`

interface InvoiceBadgeProps {
  children?: Nullable<React.ReactChild>
  differentWarning?: React.ReactChild | false
  badges: InvoiceBadgeValue[]
  maxWidth?: number
}

/**
 * This component render different badges next to its children when badges property containes the relevant badge key values
 * @param {InvoiceBadgeProps} props
 * @param props.children (optional) element to display before the badges,
 * @param props.differentWarning (optional) extra element to display,
 * @param props.badges list of displayable badges
 * @param props.maxWidth (optional) max width of the container
 * @returns
 */
function InvoiceBadge({ children, badges, differentWarning, maxWidth = 240 }: InvoiceBadgeProps) {
  return (
    <ContainerDiv maxWidth={maxWidth}>
      {children && <StyledOverflowText>{children}</StyledOverflowText>}
      {differentWarning}
      {badges.includes('new') && (
        <Badge variant={BadgeVariant.INFO}>
          <FormattedMessage id="badges.notSeenYet" defaultMessage="Új" />
        </Badge>
      )}
      {badges.includes('updated') && (
        <Badge variant={BadgeVariant.INFO}>
          <FormattedMessage id="badges.updated" defaultMessage="Frissült" />
        </Badge>
      )}
      {badges.includes('expiring') && (
        <Badge variant={BadgeVariant.ERROR}>
          <FormattedMessage id="badges.expireSoon" defaultMessage="Lejár" />
        </Badge>
      )}
      {badges.includes('expired') && (
        <Badge variant={BadgeVariant.ERROR}>
          <FormattedMessage id="badges.expired" defaultMessage="Lejárt" />
        </Badge>
      )}
      {badges.includes('nav_link') && (
        <LightTooltip
          title={
            <FormattedMessage
              id="badges.tooltips.navLink"
              defaultMessage="Ezt a számlát már a NAV rendszeréből is szinkronizáltuk"
            />
          }
        >
          <Badge variant={BadgeVariant.NAV}>
            <>
              NAV&nbsp;
              <LinkIcon />
            </>
          </Badge>
        </LightTooltip>
      )}
      {badges.includes('nav_without_preview') && (
        <LightTooltip
          title={
            <FormattedMessage
              id="badges.tooltips.navPreview"
              defaultMessage="Ehhez a NAV-tól származó számlához még nem tartozik számlakép"
            />
          }
        >
          <Badge variant={BadgeVariant.NAV}>
            <FormattedMessage id="badges.nav.noArtifact" defaultMessage="Nincs számlakép" />
          </Badge>
        </LightTooltip>
      )}
      {badges.includes('missing') && (
        <Badge variant={BadgeVariant.ERROR}>
          <FormattedMessage id="badge.deficient.data" defaultMessage="Adathiányos" />
        </Badge>
      )}
    </ContainerDiv>
  )
}

InvoiceBadge.propTyes = {
  children: PropTypes.element,
  differentWarning: PropTypes.node,
  badges: PropTypes.arrayOf(
    PropTypes.oneOf([
      'new',
      'updated',
      'expiring',
      'expired',
      'missing',
      'nav_link',
      'nav_without_preview',
      'duplicate',
    ]).isRequired
  ).isRequired,
  maxWidth: PropTypes.number,
}

export default InvoiceBadge
