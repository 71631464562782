import { getCursorFromUrl } from '@helpers'
import { getDefaultPageSize } from '@helpers/cookie'

import { INCOME_V2_DEFAULT_SORTING } from '@constants'

import attachmentActions from '../attachment/actions'
import authActions from '../auth/actions'
import filtersActions from '../filters/actions'
import actions from './actions'

const defaultPageSize = getDefaultPageSize()

const serializeCounts = ({
  count_new,
  count_expired,
  count_expiring,
}: {
  count_new?: number
  count_expired?: number
  count_expiring?: number
}) => ({
  is_new: count_new || 0,
  is_expire: count_expiring || 0,
  is_expired: count_expired || 0,
  is_updated: 0, // NOTE updated not used yet in incomes
})

export const initialState: IncomeStore = {
  details: {
    data: {} as IncomeDetailsFrontendValues,
    meta: {
      attachment_count: 0,
    },
    fetched: false,
    error: null,
    loading: false,
    uploading: false,
  },
  revenueTypes: {
    data: [],
    loading: false,
    error: null,
    fetched: false,
  },
  counts: serializeCounts({}),
  //* V2 list
  list: {
    cursor: null,
    data: [],
    error: null,
    fetched: false,
    loading: false,
    nextPageUrl: null,
    pageSize: defaultPageSize,
    prevPageUrl: null,
    ...INCOME_V2_DEFAULT_SORTING,
  },
  kpiCharts: {
    data: undefined,
    error: null,
    fetched: false,
    loading: false,
  },
}

// TODO later type should depend on generated actions' types [xxx]_[REQUEST|SUCCESS|FAILURE]
type ReducerAction = { type: string; payload: any }

function reducer(state: IncomeStore = initialState, action: ReducerAction) {
  switch (action.type) {
    case authActions.selectCompany.REQUEST:
      return { ...initialState }

    case filtersActions.initIncomeListParamsFromUrl.REQUEST: {
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
        },
      }
    }

    //* INCOME DETAILS
    case actions.uploadIncomeArtifact.REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          uploading: true,
        },
      }
    case actions.uploadIncomeArtifact.SUCCESS: {
      const { meta, ...data } = action.payload
      return {
        ...state,
        details: {
          ...state.details,
          data,
          meta,
          uploading: false,
        },
      }
    }
    case actions.uploadIncomeArtifact.FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          uploading: false,
        },
      }
    case actions.fetchIncomeDetails.REQUEST:
      return {
        ...state,
        details: {
          ...initialState.details,
          loading: true,
        },
      }
    case actions.fetchIncomeDetails.SUCCESS: {
      const { meta, ...data } = action.payload
      return {
        ...state,
        details: {
          ...state.details,
          data,
          meta,
          loading: false,
          fetched: true,
          error: null,
        },
      }
    }
    case actions.fetchIncomeDetails.FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          error: action.payload,
        },
      }

    case actions.clearIncomeDetails.REQUEST:
      return {
        ...state,
        details: { ...initialState.details },
      }

    case actions.bulkTaggingV2.SUCCESS:
    case actions.bulkCategorizationV2.SUCCESS:
      return {
        ...state,
        details: { ...initialState.details },
      }

    //* reset data when change company
    case authActions.selectCompany.SUCCESS:
      return {
        ...state,
        details: { ...initialState.details },
        revenueTypes: { ...initialState.revenueTypes },
      }

    case actions.updateIncome.SUCCESS: {
      const { meta, ...data } = action.payload
      return {
        ...state,
        details: {
          ...state.details,
          data,
          meta,
        },
      }
    }
    case actions.removeIncomeArtifact.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          data: {
            ...state.details.data,
            artifact: null,
          },
        },
      }

    //* INCOME TYPES
    case actions.fetchRevenueTypes.REQUEST:
      return {
        ...state,
        revenueTypes: {
          ...state.revenueTypes,
          loading: true,
          fetched: false,
        },
      }

    case actions.fetchRevenueTypes.SUCCESS:
      return {
        ...state,
        revenueTypes: {
          ...state.revenueTypes,
          data: action.payload,
          loading: false,
          fetched: true,
          error: null,
        },
      }

    case actions.fetchRevenueTypes.FAILURE:
      return {
        ...state,
        revenueTypes: {
          ...state.revenueTypes,
          loading: false,
          error: action.payload,
        },
      }

    case actions.createRevenueType.SUCCESS:
      return {
        ...state,
        revenueTypes: {
          ...state.revenueTypes,
          data: [
            {
              ...action.payload,
              values: state.revenueTypes.data.map(() => ({
                total: 0,
                all_paid: false,
                has_outstanding: false,
              })), // filled with empty data
            },
            ...state.revenueTypes.data,
          ],
        },
      }

    // meta
    case attachmentActions.increaseAttachmentCount.REQUEST: {
      if (action.payload.documentType === 'income') {
        return {
          ...state,
          details: {
            ...state.details,
            meta: {
              ...state.details.meta,
              attachment_count: state.details.meta.attachment_count + 1,
            },
          },
        }
      }
      return state
    }
    case attachmentActions.decreaseAttachment.REQUEST: {
      if (action.payload.documentType === 'income') {
        return {
          ...state,
          details: {
            ...state.details,
            meta: {
              ...state.details.meta,
              attachment_count: state.details.meta.attachment_count - 1,
            },
          },
        }
      }
      return state
    }

    case attachmentActions.updateAttachmentCount.REQUEST: {
      if (action.payload.documentType === 'income') {
        return {
          ...state,
          details: {
            ...state.details,
            meta: {
              ...state.details.meta,
              attachment_count: action.payload.count,
            },
          },
        }
      }
      return state
    }

    //* V2
    case actions.fetchIncomeListV2.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          fetched: false,
        },
      }

    case actions.fetchIncomeListByPagingV2.REQUEST:
    case actions.fetchIncomeDetailsByPagingV2.REQUEST: {
      const cursor = getCursorFromUrl(action.payload.url)
      return {
        ...state,
        list: {
          ...state.list,
          cursor,
          loading: true,
          fetched: false,
        },
      }
    }

    case actions.fetchIncomeListV2.SUCCESS:
    case actions.fetchIncomeListByPagingV2.SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          prevPageUrl: action.payload.previous,
          nextPageUrl: action.payload.next,
          loading: false,
          fetched: true,
          error: null,
          ...(action.payload.isCursorDropped && {
            cursor: action.payload.previousCursor,
          }),
        },
      }
    }

    case actions.fetchIncomeListV2.FAILURE:
    case actions.fetchIncomeListByPagingV2.FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.payload,
        },
      }

    case actions.updateOrderV2.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
          error: null,
          order: action.payload.order,
          orderBy: action.payload.orderBy,
        },
      }

    case actions.updateRowsPerPageV2.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
          error: null,
          pageSize: action.payload.pageSize,
        },
      }

    case filtersActions.toggleIncomeListDateFilter.REQUEST:
    case filtersActions.updateIncomeListFilters.REQUEST:
    case filtersActions.resetIncomeListFilters.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
          error: null,
        },
        kpiCharts: {
          ...state.kpiCharts,
          error: null,
          loading: true,
        },
      }

    case actions.fetchIncomeCharts.REQUEST:
      return {
        ...state,
        kpiCharts: {
          ...state.kpiCharts,
          error: null,
          loading: true,
          fetched: false,
        },
      }

    case actions.fetchIncomeCharts.SUCCESS:
      return {
        ...state,
        kpiCharts: {
          ...state.kpiCharts,
          data: action.payload,
          loading: false,
          fetched: true,
        },
      }

    case actions.fetchIncomeCharts.FAILURE:
      return {
        ...state,
        kpiCharts: {
          ...state.kpiCharts,
          loading: false,
          error: action.payload,
        },
      }

    case actions.triggerIncomeListUpdate.REQUEST:
    case actions.resetPagination.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
          error: null,
        },
      }

    default:
      return state
  }
}

export default reducer
