import { AxiosResponse } from 'axios'
import { call, delay, put, select, takeLatest } from 'redux-saga/effects'

import { callUrl } from '@services/common/api'
import { CommonAxiosPayload } from '@services/types'

import {
  generateBackgroundProcessActionPayload,
  getActiveCompanyId,
  getErrorMessage,
  getExpenseListFiltersFromStore,
} from '@helpers'

import { BackgroundProcessActions, TYPING_INTERVAL } from '@constants'

import actions from './actions'
import * as api from './api'

// single approve
function* expenseApproveSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<CommonAxiosPayload<unknown>>) {
  try {
    const response: AxiosResponse<{ meta: ExpenseDetailsMeta }> = yield call(callUrl, payload)
    yield put(actions.expenseApprove.success(response.data.meta))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

// bulk approve
function* bulkApproveSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<BackgroundActionRequestPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const filters: ApiPageParams = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.TOGGLE_APPROVE_EXPENSES,
      payload,
      filters
    )
    const response: AxiosResponse = yield call(api.expensesBackgroundAction, companyId, apiPayload)
    yield put(actions.bulkApprove.success())
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

function* createJobNumberSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<string>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<{ name: string }> = yield call(api.createJobNumber, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

function* searchJobNumbersSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<string>) {
  try {
    yield delay(TYPING_INTERVAL)
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<Array<{ name: string }>> = yield call(api.searchJobNumbers, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.createJobNumber.REQUEST, createJobNumberSaga)
  yield takeLatest(actions.searchJobNumbers.REQUEST, searchJobNumbersSaga)
  // approve
  yield takeLatest(actions.expenseApprove.REQUEST, expenseApproveSaga)
  yield takeLatest(actions.bulkApprove.REQUEST, bulkApproveSaga)
}
