import { AxiosResponse } from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'

import { CommonAxiosPayload } from '@services/types'

import { getErrorMessage, getFormErrors } from '@helpers'

import { callUrl as callUrlApi } from '../common/api'
import actions from './actions'

function* callCustomFieldUrlSaga<Payload>({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<CommonAxiosPayload<Payload>>) {
  try {
    const response: AxiosResponse<unknown> = yield call(callUrlApi, payload)
    yield put(actions.callCustomFieldUrl.success(response.data))
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* callCustomFieldRemoveUrlSaga<Payload>({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<CommonAxiosPayload<Payload>>) {
  try {
    const response: AxiosResponse<unknown> = yield call(callUrlApi, payload)
    yield put(actions.callCustomFieldUrl.success(response.data))
    yield call(resolve)
  } catch (error) {
    const errorMessage = getErrorMessage(error as any)
    yield call(reject, errorMessage)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.callCustomFieldUrl.REQUEST, callCustomFieldUrlSaga)
  yield takeLatest(actions.callCustomFieldRemoveUrl.REQUEST, callCustomFieldRemoveUrlSaga)
}
