import __snakeCase from 'lodash/snakeCase'
import Qs from 'qs'
import { Dispatch } from 'redux'

import { AUTH_TOKEN_NAME, EMPTY_CUSTOM_FIELD_OPTION_VALUE } from '@constants'

import actions from './auth/actions'

type AuthToken = {
  auth_token: string
  expires: string
}

export function setToken(token: AuthToken) {
  localStorage.setItem(AUTH_TOKEN_NAME, JSON.stringify(token))
}

export function clearToken() {
  localStorage.removeItem(AUTH_TOKEN_NAME)
}

export function getToken() {
  const storedToken = localStorage.getItem(AUTH_TOKEN_NAME)
  if (storedToken) {
    // parse it down into an object
    const token = JSON.parse(storedToken) as AuthToken
    return token
  }
  return false
}

export function getAuthToken() {
  const token = getToken()
  return token ? token.auth_token : null
}

export function isUserLoggedIn() {
  const token = getToken()
  if (token) {
    const tokenAlive = tokenIsNotExpired(token)
    if (tokenAlive === 0) {
      clearToken()
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

export function tokenIsNotExpired(token: AuthToken) {
  // default is 10 hrs
  const EXPIRATION_RENEWAL_TIME = 10 * 60 * 1000 // 10 mins

  const expiredDate = new Date(token.expires)
  const now = new Date()
  const expiredTime = expiredDate.getTime()
  const nowTime = now.getTime()

  // token is not expired
  if (expiredTime > nowTime) {
    // renew token when nearly expired
    if (expiredTime - EXPIRATION_RENEWAL_TIME < nowTime) {
      return 1 // nearly expired
    } else {
      return 2 // token is alive
    }
  } else {
    // expired token
    return 0
  }
}

export function checkTokenExpiraiton(dispatch: Dispatch, token: AuthToken) {
  // this just all works to compare the total seconds of the created
  // time of the token vs the EXPIRATION_RENEWAL_TIME seconds
  // check and call action to update token when needed
  const tokenAlive = tokenIsNotExpired(token)
  // token is not expired: tokenAlive is equal with 1 or 2
  if (tokenAlive > 0) {
    // renew token when nearly expired
    if (tokenAlive === 1) {
      dispatch(actions.renewToken.request())
    }
  } else {
    // expired token
    // need to clear token before dispatch to avoid infinite state update loop
    clearToken()
    // notify reducer to display expired warning dialog in InitializeApplication
    dispatch(actions.expiredToken.request())
  }
}

/**
 * Helper function to transform object with camelCase keys to object with snake_case keys (non recursive)
 * @param {object} params - object with camelCase keys
 * @returns object with snake_case keys (only 1 level deep)
 */
export function transformV2ParamsToV1(params: object): object {
  return Object.fromEntries(Object.entries(params).map(([key, value]) => [__snakeCase(key), value]))
}

export function serializeParamsWithCustomFields({
  customFields,
  ...params
}: Record<string, unknown> & { customFields?: CustomFieldsFilter[] }) {
  let customFieldsSearchParamsString = ''
  if (customFields?.length) {
    const mappedCustomFields = customFields.map(({ key, value }) => ({
      key,
      value: value === EMPTY_CUSTOM_FIELD_OPTION_VALUE ? null : value,
    }))

    customFieldsSearchParamsString = JSON.stringify(mappedCustomFields)
    return Qs.stringify({
      ...params,
      customFields: customFieldsSearchParamsString,
    })
  }

  return Qs.stringify(params)
}
