import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

import { CommonAxiosPayload } from '@services'

import { useSuccessDialog } from '@contexts/SuccessDialogProvider'

import { Button } from '@components/ui'

import { TaxationTaskTypes } from '../../constants'
import { ModifyTaxDialog } from './elements'
import { ModifyDialogState, SzjaModifyDialogProps, TbjSzochoModifyDialogProps } from './types'

import { TaxationTaskDoneTitleMessage } from '../../messages'

interface CommonModifyTaxButtonProps {
  callUrl: AsyncFunction<CommonAxiosPayload<unknown>, unknown>
  refetchReports: VoidFunction
  url: string
}

type TbjSzochoModifyTaxButtonProps = CommonModifyTaxButtonProps & TbjSzochoModifyDialogProps

type SzjaModifyTaxButtonProps = CommonModifyTaxButtonProps & SzjaModifyDialogProps

export type ModifyTaxButtonProps = TbjSzochoModifyTaxButtonProps | SzjaModifyTaxButtonProps

export function ModifyTaxButton({ callUrl, url, refetchReports, ...restProps }: ModifyTaxButtonProps) {
  const [dialogState, setDialogState] = React.useState<ModifyDialogState | null>(null)
  const [open, setOpen] = React.useState(false)
  const setSuccessMessage = useSuccessDialog()

  function openDialogFormState() {
    setDialogState(ModifyDialogState.form)
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  function onSubmit(values: Record<string, number>) {
    return callUrl({ url, data: values, method: 'post', isFormError: true })
  }

  function onSubmitSuccess() {
    handleClose()
    refetchReports()
    // success message
    setSuccessMessage({
      title: TaxationTaskDoneTitleMessage,
      message: (
        <FormattedMessage
          id="taxation.dialogs.modifyDialog.message"
          defaultMessage="Sikeresen felülírtad a fizetendő adót, amit behelyeztünk a Tranzakciók menü, Adók közé."
        />
      ),
    })
  }

  return (
    <>
      <Button onClick={openDialogFormState} variant="primaryContained">
        <FormattedMessage id="taxation.buttons.override" defaultMessage="Felülírás" />
      </Button>
      <ModifyTaxDialog
        dialogState={dialogState}
        onClose={handleClose}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
        open={open}
        setDialogState={setDialogState}
        {...restProps}
      />
    </>
  )
}

ModifyTaxButton.propTypes = {
  callUrl: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  refetchReports: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(Object.values(TaxationTaskTypes)).isRequired,
}
