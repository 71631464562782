import { InvoiceStatus, QuickUserFeaturePermissions } from '@constants'

import { isAdminOrAccountant } from './authorization'
import { isNAVInvoice } from './integrations'

export function isInvoiceProcessed(values: ExpenseDetailsFrontendValues) {
  const notEditableInvoiceStatusList: ExpenseDetailsStatus[] = [InvoiceStatus.CHECKED, InvoiceStatus.EXPORTED]
  return notEditableInvoiceStatusList.includes(values.status)
}

export function isVisibleForAccountantOrAdmin(userCompany: Company) {
  // can't use "permissionDeniedForUser" here, because that helper is controlled via plan-permission
  return isAdminOrAccountant(userCompany.role) && !userCompany[QuickUserFeaturePermissions.denyAccountingPermission]
}

export function getInvoiceBulkUpdateData({
  id,
  partner,
  payment_method,
  company: company_id,
  tags,
  vat_area,
  assignments,
  paid_through,
}: ExpenseDetailsFrontendValues) {
  return {
    id,
    company_id,
    partner_id: partner?.id ?? null,
    partner_name: partner?.name ?? null,
    payment_method,
    vat_area,
    paid_through,
    tags,
    expense_type: assignments[0].expense_type,
    assignment_tags: assignments[0].tags,
  }
}

export function getIncomeBulkUpdateData({
  id,
  partner,
  payment_method,
  company: company_id,
  tags,
  vat_area,
  assignments,
}: IncomeDetailsFrontendValues) {
  return {
    id,
    company_id,
    partner_id: partner?.id ?? null,
    partner_name: partner?.name ?? null,
    payment_method,
    vat_area,
    tags,
    revenue_type: assignments[0].revenue_type,
    income_assignment_tags: assignments[0].tags,
  }
}

export function parseIfExists(value?: string) {
  if (value != null) {
    return parseInt(value, 10)
  }
  return value
}

export function isInvoiceClassifyConfirmNeeded(invoiceDetails: ExpenseDetailsFrontendValues) {
  return Boolean(invoiceDetails?.id && !isInvoiceProcessed(invoiceDetails) && !isNAVInvoice(invoiceDetails))
}
