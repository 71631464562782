import React from 'react'
import PropTypes from 'prop-types'

import { ClickAwayListener, Popover, PopoverProps } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { FormattedMessage } from 'react-intl'
import { IdType } from 'react-table'
import styled from 'styled-components'

import { OldIconButton, SecondaryLinkButton, Typography } from '@components/ui'

const StyledPopover = styled(Popover)`
  & .MuiPaper-root {
    margin-left: 4px;
    overflow: visible;
    display: flex;
    min-height: 56px;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 10px;

    > div {
      white-space: nowrap;
      padding-right: 20px;
    }

    ::before,
    ::after {
      display: block;
      content: '';
      position: absolute;
      left: 8px;
      border-width: 7px;
      border-style: solid;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom: 0;
    }

    ::before {
      z-index: 1;
      border-top-color: ${({ theme }) => theme.colors.common.paperStroke};
      bottom: -8px;
    }

    ::after {
      z-index: 2;
      border-top-color: ${({ theme }) => theme.colors.gray[0]};
      bottom: -7px;
    }

    ${OldIconButton} {
      position: absolute;
      top: 5px;
      right: 5px;
      border-radius: 50%;
    }

    ${SecondaryLinkButton} {
      margin-top: 10px;
    }
  }
`

interface SelectAllPopoverProps {
  isAllSelected: boolean
  selectedCount: number
  anchorEl: PopoverProps['anchorEl']
  onClose: VoidFunction
  onSelectChange: (values: Partial<{ selectedRowIds: Record<IdType<object>, boolean>; isAllSelected: boolean }>) => void
}

export function SelectAllPopover({
  anchorEl,
  isAllSelected,
  onClose,
  onSelectChange,
  selectedCount,
}: SelectAllPopoverProps) {
  return (
    <StyledPopover
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      anchorEl={anchorEl}
      open
      elevation={9}
    >
      <ClickAwayListener onClickAway={onClose}>
        <div>
          <OldIconButton onClick={onClose}>
            <ClearIcon color="inherit" fontSize="inherit" />
          </OldIconButton>
          {isAllSelected ? (
            <Typography size="700-sm">
              <FormattedMessage
                id="invoiceListTable.selectAll.globalSelectedTextWithoutTotalCount"
                defaultMessage="Az összes, a keresésnek megfelelő eredmény kijelölve."
              />
            </Typography>
          ) : (
            <>
              <Typography size="700-sm">
                <FormattedMessage
                  id="invoiceListTable.selectAll.pageSelectedText"
                  defaultMessage="Ezen az oldalon minden elemet ({selectedCount}) kijelöltél."
                  values={{
                    selectedCount,
                  }}
                />
              </Typography>
              <SecondaryLinkButton onClick={() => onSelectChange({ isAllSelected: true })}>
                <FormattedMessage
                  id="invoiceListTable.selectAll.selectBtnTextWithoutTotalCount"
                  defaultMessage="Az összes, a keresésnek megfelelő eredmény kijelölése"
                />
              </SecondaryLinkButton>
            </>
          )}
        </div>
      </ClickAwayListener>
    </StyledPopover>
  )
}

SelectAllPopover.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.element.isRequired]).isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  selectedCount: PropTypes.number.isRequired,
}
