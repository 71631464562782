import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  ButtonBase,
  Fade,
  FormControlLabel,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  MenuList,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { FilterOptions } from '@components/filters/elements'
import { Checkbox } from '@components/ui/Checkbox'
import { Typography } from '@components/ui/Typography'

import { FilterFieldLabel } from '../FilterFieldLabel'
import { usePlaceholderMessage } from './usePlaceholderMessage'
import { useSearchFilter } from './useSearchFilter'

import { filterFormStyles } from '@styles/filters'
import { SearchLabelMessage } from '@messages'
import { searchFilterStyles } from '../../styles'

const useStyles = makeStyles(searchFilterStyles)
const useFieldStyles = makeStyles(filterFormStyles)

const MenuHeadingTypography = styled(Typography)`
  margin-bottom: 10px;
  text-transform: uppercase;

  label + & {
    margin-top: 10px;
  }
`

const SearchOptionsWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  gap: 10px 20px;
  grid-template-columns: repeat(4, 1fr);
`

const SearchTooltipText = (
  <FormattedMessage
    id="filterBar.searchFilter.tooltip.info"
    defaultMessage="A Keresés szűrői között “VAGY” kapcsolat van. Az eredmények között minden megjelenik, amire legalább egy feltétel igaz."
  />
)

const commonFilterFieldLabelProps = {
  tooltipText: SearchTooltipText,
  label: SearchLabelMessage,
}

interface FormSearchFilterProps {
  disabled?: boolean
  onChange: (payload: Pick<CommonSearchFilters, 'search'> | Pick<CommonSearchFilters, 'searchFields'>) => void
  onInputValueChange?: (payload: CommonSearchFilters['search']) => void
  search: CommonSearchFilters['search']
  searchFieldExtraOptions?: SearchFilterOption[]
  searchFieldOptions: SearchFilterOption[]
  searchFields: CommonSearchFilters['searchFields']
}

export function FormSearchFilter({
  onChange,
  onInputValueChange,
  search,
  searchFieldExtraOptions = [],
  searchFieldOptions,
  searchFields,
}: Exclude<FormSearchFilterProps, 'disabled'>) {
  const classes = useStyles()
  const fieldClasses = useFieldStyles()

  const { value, inputPlaceholder, handleInputChange, handleInputClear, handleEscClear, handleChange } =
    useSearchFilter({
      debounce: false,
      onChange,
      onInputValueChange,
      search,
      searchFields,
      searchFieldOptions,
      searchFieldExtraOptions,
    })

  // We wish to display the single option as a disabled and checked option
  const isSingleOptionSearch = searchFieldOptions.length === 1

  return (
    <div>
      <TextField
        data-testid="search-filter"
        classes={{
          root: fieldClasses.textFieldRoot,
        }}
        className="form-control"
        fullWidth
        label={<FilterFieldLabel {...commonFilterFieldLabelProps} />}
        margin="normal"
        name="search"
        onChange={handleInputChange}
        onKeyDown={handleEscClear}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: fieldClasses.inputRoot,
          },
          endAdornment: (
            <>
              <InputAdornment component={Fade} in={Boolean(search)} position="end">
                <ButtonBase tabIndex={-1} className={fieldClasses.inputClearButton} onClick={handleInputClear}>
                  <ClearIcon color="inherit" fontSize="inherit" />
                </ButtonBase>
              </InputAdornment>
              <InputAdornment position="end" className={fieldClasses.searchIcon}>
                <SearchIcon fontSize="inherit" color="inherit" />
              </InputAdornment>
            </>
          ),
        }}
        InputLabelProps={{
          shrink: true,
          className: cx(fieldClasses.label, fieldClasses.extendedLabel),
        }}
        placeholder={inputPlaceholder}
        value={value}
      />

      <SearchOptionsWrapper>
        {[...searchFieldOptions, ...searchFieldExtraOptions].map(option => {
          const isOnlySelected = searchFields.includes(option.key) && searchFields.length === 1
          return (
            <FormControlLabel
              key={option.key}
              classes={{ label: fieldClasses.formControlLabel, root: classes.formControlRoot }}
              control={
                <Checkbox
                  disabled={isSingleOptionSearch || isOnlySelected}
                  checked={isSingleOptionSearch || searchFields.includes(option.key)}
                  className={cx(classes.checkbox, { onlySelected: isOnlySelected })}
                  name={option.key}
                  onChange={handleChange}
                />
              }
              label={option.label}
            />
          )
        })}
      </SearchOptionsWrapper>
    </div>
  )
}

interface WidgetSearchFilterProps extends FormSearchFilterProps {
  searchFieldPresets?: SearchFilterPreset[]
}
/**
 *  SearchFilter used in the PageFilterBar when searching in the table
 *
 * @param {WidgetSearchFilterProps} {
 *   disabled = false,
 *   onChange,
 *   onInputValueChange
 *   search,
 *   searchFieldExtraOptions,
 *   searchFieldOptions,
 *   searchFieldPresets,
 *   searchFields,
 * }
 */
export function WidgetSearchFilter({
  disabled = false,
  onChange,
  onInputValueChange,
  search,
  searchFieldExtraOptions = [],
  searchFieldOptions,
  searchFieldPresets = [],
  searchFields,
}: WidgetSearchFilterProps) {
  const classes = useStyles()
  const fieldClasses = useFieldStyles()
  const [open, setOpen] = React.useState(false)
  const anchorElRef = React.useRef<HTMLDivElement>(null)

  const {
    value,
    inputPlaceholder,
    handleInputChange,
    handleInputClear,
    handleEscClear,
    handleChange,
    handlePresetClick,
    searchSelectionState,
  } = useSearchFilter({
    debounce: true,
    onChange,
    onInputValueChange,
    search,
    searchFields,
    searchFieldOptions,
    searchFieldExtraOptions,
  })

  // We wish to display the single option as a disabled and checked option
  const isSingleOptionSearch = searchFieldOptions.length + searchFieldExtraOptions.length === 1

  const placeholderMessage = usePlaceholderMessage(searchSelectionState, isSingleOptionSearch)

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <div className={classes.root} ref={anchorElRef} data-testid="widget-search-filter">
      <div className={classes.topRow}>
        <InputLabel className={classes.inputLabel}>{SearchLabelMessage}</InputLabel>
        <Button className={classes.button} onClick={handleOpen}>
          {placeholderMessage}
        </Button>
      </div>

      <Box component="div" display="flex" justifyContent="space-between">
        <Input
          className={classes.input}
          disableUnderline
          endAdornment={
            <InputAdornment component={Fade} in={Boolean(search)} position="end">
              <ButtonBase tabIndex={-1} className={fieldClasses.inputClearButton} onClick={handleInputClear}>
                <ClearIcon color="inherit" fontSize="inherit" />
              </ButtonBase>
            </InputAdornment>
          }
          onChange={handleInputChange}
          onKeyDown={handleEscClear}
          placeholder={inputPlaceholder}
          startAdornment={
            <InputAdornment className={classes.inputAdornment} position="start">
              <SearchIcon fontSize="inherit" color="inherit" />
            </InputAdornment>
          }
          value={value}
        />

        <FilterOptions
          anchorEl={anchorElRef.current}
          disabled={disabled}
          onClick={handleOpen}
          onClose={handleClose}
          open={open}
          variant="search"
        >
          <div className={classes.menuRoot}>
            <div className={classes.menu}>
              {searchFieldExtraOptions.length > 0 && (
                <MenuHeadingTypography size="700-xs" color="gray-80">
                  <FormattedMessage id="searchFilter.menu.headings.defaultsLabel" defaultMessage="Alapértelmezett" />
                </MenuHeadingTypography>
              )}
              {searchFieldOptions.map(option => {
                const isOnlySelected = searchFields.includes(option.key) && searchFields.length === 1
                return (
                  <FormControlLabel
                    key={option.key}
                    classes={{ label: classes.formControlLabel, root: classes.formControlRoot }}
                    control={
                      <Checkbox
                        disabled={isSingleOptionSearch || isOnlySelected}
                        checked={isSingleOptionSearch || searchFields.includes(option.key) || searchFields.length === 0}
                        className={cx(classes.checkbox, { onlySelected: isOnlySelected })}
                        name={option.key}
                        onChange={handleChange}
                      />
                    }
                    label={option.label}
                  />
                )
              })}
              {searchFieldExtraOptions.length > 0 && (
                <MenuHeadingTypography size="700-xs" color="gray-80">
                  <FormattedMessage id="searchFilter.menu.headings.othersLabel" defaultMessage="További lehetőségek" />
                </MenuHeadingTypography>
              )}
              {searchFieldExtraOptions.map(option => {
                const isOnlySelected = searchFields.includes(option.key) && searchFields.length === 1
                return (
                  <FormControlLabel
                    key={option.key}
                    classes={{ label: classes.formControlLabel, root: classes.formControlRoot }}
                    control={
                      <Checkbox
                        disabled={isSingleOptionSearch || isOnlySelected}
                        checked={isSingleOptionSearch || searchFields.includes(option.key)}
                        className={cx(classes.checkbox, { onlySelected: isOnlySelected })}
                        name={option.key}
                        onChange={handleChange}
                      />
                    }
                    label={option.label}
                  />
                )
              })}
            </div>
            {searchFieldPresets.length > 0 && (
              <MenuList className={cx(classes.list)}>
                {searchFieldPresets.map(preset => (
                  <MenuItem
                    key={preset.key}
                    className={classes.listItem}
                    onClick={handlePresetClick(preset.optionKeys)}
                  >
                    <Typography color="inherit">{preset.label}</Typography>
                  </MenuItem>
                ))}
              </MenuList>
            )}
          </div>
        </FilterOptions>
      </Box>
    </div>
  )
}

const propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  searchFields: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  searchFieldOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]).isRequired,
    }).isRequired
  ).isRequired,
  searchFieldExtraOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]).isRequired,
    }).isRequired
  ),
  searchFieldPresets: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      optionKeys: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]).isRequired,
    }).isRequired
  ),
}

FormSearchFilter.propTypes = propTypes
WidgetSearchFilter.propTypes = propTypes
