import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects'

import {
  dokumentListWorkerRunner,
  expenseListWorkerRunner,
  incomeListWorkerRunner,
  parseFiltersFromUrlWorkerRunner,
} from '@webWorkers'
import { JOB_STATUS_FINISHED } from '@services/background/process'

import {
  AMPLITUDE_EVENTS,
  downloadFileWithURL,
  generateAPIPayload,
  generateBackgroundProcessActionPayload,
  getActiveCompanyId,
  getDokumentsFiltersFromStore,
  getDokumentsParamsFromStore,
  getErrorMessage,
  getErrorMessageWithConsent,
  getRFFFormErrors,
  getUrlFilterOptionsFromStore,
  sendAmplitudeData,
} from '@helpers'

import { BackgroundProcessActions, TYPING_INTERVAL } from '@constants'

import attachmentActions from '../attachment/actions'
import { BackgroundDownloadEmailProcess } from '../background/process'
import { callUrl } from '../common/api'
import dashboardActions from '../dashboard/actions'
import { bulkTagging as bulkTaggingApi, fetchTags as fetchTagsApi } from '../dashboard/api'
import { fetchExpenseListV2 } from '../expense/api'
import filtersActions from '../filters/actions'
import { fetchIncomeListV2 } from '../income/api'
import { fetchPartners } from '../partners/api'
import actions from './actions'
import * as api from './api'
import { DokumentAiRecognitionBackgroundAction } from './backgroundActions'

function* initDokumentListPageLoadSaga({
  payload: { config, filtersStateKey, location, navigate },
  meta: { resolve, reject },
}) {
  try {
    const companyId = yield select(getActiveCompanyId)
    // gather tags as we're going to need them for mapping purposes for the list
    const tagsResponse = yield call(fetchTagsApi, companyId)
    yield put(dashboardActions.fetchTags.success(tagsResponse.data))
    // gather documet types as we're going to need them for mapping purposes for the list
    const documentTypesResponse = yield call(api.fetchDokumentTypes, companyId)
    yield put(actions.fetchDokumentTypes.success(documentTypesResponse.data))

    const filterOptions = yield select(getUrlFilterOptionsFromStore, filtersStateKey)

    //* >> sync from url to store: call worker
    const { filters, params, validationLevel } = yield call(parseFiltersFromUrlWorkerRunner, {
      config,
      filterOptions,
      location,
    })
    yield all([
      put(filtersActions.initDokumentListFiltersFromUrl.request({ filters })),
      put(filtersActions.initDokumentListParamsFromUrl.request(params)),
    ])

    yield call(resolve, validationLevel)
    yield put(actions.fetchDokuments.request({ navigate }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// list | modify pageSize or ordering (order, orderBy) | sideeffects
function* fetchDokumentListV2Saga({ payload: { navigate } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const params = yield select(getDokumentsParamsFromStore, { withCursor: true })
    const tags = yield select(({ dashboard: { tags } }) => tags.data)

    const listResponse = yield call(api.fetchDokuments, companyId, params)
    //* call worker
    const workerResults = yield call(dokumentListWorkerRunner, {
      dokuments: listResponse.data.results,
      tags,
    })

    yield put(
      actions.fetchDokuments.success({
        data: workerResults,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
    //* << sync from store to url
    const storedFilters = yield select(getDokumentsParamsFromStore)
    yield put(filtersActions.syncFiltersToUrl.request({ navigate, filters: storedFilters }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchDokuments.failure(errorMsg))
  }
}

function* fetchDokumentListSimpleSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const params = yield select(getDokumentsParamsFromStore)
    const tags = yield select(({ dashboard: { tags } }) => tags.data)

    const listResponse = yield call(api.fetchDokuments, companyId, params)
    //* call worker
    const workerResults = yield call(dokumentListWorkerRunner, {
      dokuments: listResponse.data.results,
      tags,
    })

    yield put(
      actions.fetchDokuments.success({
        data: workerResults,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchDokuments.failure(errorMsg))
  }
}
// cursor pagination (next or previous url)
function* fetchDokumentsByPagingSaga({ payload: { url } }) {
  try {
    const tags = yield select(({ dashboard: { tags } }) => tags.data)
    const listResponse = yield call(api.fetchDokumentsByUrl, url)
    //* call worker
    const workerResults = yield call(dokumentListWorkerRunner, {
      dokuments: listResponse.data.results,
      tags,
    })

    yield put(
      actions.fetchDokumentsByPaging.success({
        data: workerResults,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchDokumentsByPaging.failure(errorMsg))
  }
}

// FEATURES
// create weblink
function* createWeblinkSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.createWeblink, companyId, payload)
    yield put(actions.createWeblink.success())
    yield call(resolve)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

// BULK REMOVE
function* bulkRemoveDokumentsSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getDokumentsFiltersFromStore)
    const apiPayload = generateAPIPayload(payload, filters)
    yield call(api.bulkRemoveDokuments, companyId, apiPayload)
    yield put(actions.bulkRemoveDokuments.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}
// details remove but using bulk action
function* removeDokumentSaga({ payload, meta: { resolve, reject } }) {
  try {
    const { data } = generateAPIPayload(payload.data, {})
    yield call(callUrl, { ...payload, data })
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// BULK TAGGING
function* bulkTaggingSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(bulkTaggingApi, companyId, payload)
    yield put(actions.bulkTagging.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// DOWNLOAD
function* downloadDokumentSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(callUrl, payload)
    yield call(downloadFileWithURL, response)
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// BULK DOWNLOAD
function* downloadDokumentsSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getDokumentsFiltersFromStore)
    const apiPayload = generateAPIPayload(payload, filters)
    const response = yield call(api.downloadDokuments, companyId, apiPayload)
    const response2 = yield call(BackgroundDownloadEmailProcess.start, {
      id: response.data.id,
      company_id: companyId,
    })
    // download file when it not resolved by send-in-email cancel
    if (!response2.data.send_email) {
      yield call(downloadFileWithURL, response2)
    }
    yield call(resolve, response2)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* abortDownloadDokumentsSaga() {
  yield call(BackgroundDownloadEmailProcess.stop)
}

function* uploadDokumentsSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.bulkUploadDokuments, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    // do not parse error here, it's handled in the useFileDropzone hook
    yield call(reject, error)
  }
}

function* renameDokumentSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.renameDokument, companyId, payload)
    yield put(actions.renameDokument.success(payload))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// ATTACH SEARCH
function* searchExpensesSaga({ payload, meta: { resolve, reject } }) {
  try {
    yield delay(TYPING_INTERVAL)
    const companyId = yield select(getActiveCompanyId)
    const tags = yield select(({ dashboard: { tags } }) => tags.data)

    const listResponse = yield call(fetchExpenseListV2, companyId, payload)
    //* call worker
    const workerResults = yield call(expenseListWorkerRunner, {
      expenses: listResponse.data.results,
      tags,
      expenseTypes: [], // ignored in UI
    })

    yield call(resolve, { data: workerResults, next: listResponse.data.next, previous: listResponse.data.previous })
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* searchExpensesByUrlSaga({ payload, meta: { resolve, reject } }) {
  try {
    const tags = yield select(({ dashboard: { tags } }) => tags.data)

    const listResponse = yield call(callUrl, payload)
    //* call worker
    const workerResults = yield call(expenseListWorkerRunner, {
      expenses: listResponse.data.results,
      tags,
      expenseTypes: [], // ignored in UI
    })

    yield call(resolve, { data: workerResults, next: listResponse.data.next, previous: listResponse.data.previous })
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* searchPartnersSaga({ payload, meta: { resolve, reject } }) {
  try {
    yield delay(TYPING_INTERVAL)
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(fetchPartners, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* searchIncomesSaga({ payload, meta: { resolve, reject } }) {
  try {
    yield delay(TYPING_INTERVAL)
    const companyId = yield select(getActiveCompanyId)
    const tags = yield select(({ dashboard: { tags } }) => tags.data)

    const listResponse = yield call(fetchIncomeListV2, companyId, payload)
    //* call worker
    const workerResults = yield call(incomeListWorkerRunner, {
      revenues: listResponse.data.results,
      tags,
      revenueTypes: [], // ignored in UI
    })

    yield call(resolve, { data: workerResults, next: listResponse.data.next, previous: listResponse.data.previous })
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* searchIncomesByUrlSaga({ payload, meta: { resolve, reject } }) {
  try {
    const tags = yield select(({ dashboard: { tags } }) => tags.data)

    const listResponse = yield call(callUrl, payload)
    //* call worker
    const workerResults = yield call(incomeListWorkerRunner, {
      revenues: listResponse.data.results,
      tags,
      revenueTypes: [], // ignored in UI
    })

    yield call(resolve, { data: workerResults, next: listResponse.data.next, previous: listResponse.data.previous })
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* searchDokumentsSaga({ payload, meta: { resolve, reject } }) {
  try {
    yield delay(TYPING_INTERVAL)
    const companyId = yield select(getActiveCompanyId)
    const tags = yield select(({ dashboard: { tags } }) => tags.data)

    const listResponse = yield call(api.fetchDokuments, companyId, payload)
    //* call worker
    const workerResults = yield call(dokumentListWorkerRunner, {
      dokuments: listResponse.data.results,
      tags,
    })

    yield call(resolve, { data: workerResults, next: listResponse.data.next, previous: listResponse.data.previous })
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* searchDokumentsByUrlSaga({ payload, meta: { resolve, reject } }) {
  try {
    const tags = yield select(({ dashboard: { tags } }) => tags.data)

    const listResponse = yield call(callUrl, payload)
    //* call worker
    const workerResults = yield call(dokumentListWorkerRunner, {
      dokuments: listResponse.data.results,
      tags,
    })

    yield call(resolve, { data: workerResults, next: listResponse.data.next, previous: listResponse.data.previous })
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* detachDokumentSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.detachDokument, companyId, payload)
    if (payload.documentType !== 'partner') {
      yield put(attachmentActions.decreaseAttachment.request(payload))
    }
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// details
function* fetchDokumentDetailsSaga({ payload }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchDokumentDetails, companyId, payload)
    // --amplitude tracking start
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.VIEW_DOCUMENT)
    // --amplitude tracking end
    yield put(actions.fetchDokumentDetails.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchDokumentDetails.failure(errorMsg))
  }
}

// handle paging on details with v2 data
function* fetchDokumentDetailsByPagingSaga({ payload: { url, isNext }, meta: { resolve, reject } }) {
  try {
    const listResponse = yield call(api.fetchDokumentDetailsByPaging, url)

    // skip worker because do not need to map these values here, do not show this list for user
    const results = yield call(
      dokumets =>
        dokumets.map(({ simpleTagIds, ...dokument }) => ({
          ...dokument,
          simpleTags: [],
        })),
      listResponse.data.results
    )

    yield put(
      actions.fetchDokumentsByPaging.success({
        data: results,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
    // return the id of the next dokument to fetch
    const nextId = isNext ? results[0].id : results[results.length - 1].id
    yield call(resolve, nextId)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    const errorResponseData = error?.response?.data || {}
    yield call(reject, { data: errorResponseData, msg: errorMsg })
  }
}

function* updateDokumentDetailsSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.updateDokumentDetails, companyId, payload)

    yield put(actions.updateDokumentDetails.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

// dokument types
function* fetchDokumentTypesSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchDokumentTypes, companyId)
    yield put(actions.fetchDokumentTypes.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchDokumentTypes.failure(errorMsg))
  }
}

function* createDokumentTypeSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.createDokumentType, payload) // company exists in payload
    yield put(actions.createDokumentType.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// AI recognition
function* startAiRecognitionSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(DokumentAiRecognitionBackgroundAction.start, payload)
    yield call(resolve, response)
  } catch (error) {
    const errorWithConsent = getErrorMessageWithConsent(error, 'hasDocumentAiConsent')
    yield call(reject, errorWithConsent)
  }
}

function* continueAiRecognitionSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(DokumentAiRecognitionBackgroundAction.continueProcess, payload)
    yield call(resolve, response)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* stopAiRecognitionSaga() {
  yield call(DokumentAiRecognitionBackgroundAction.stop)
}

// user viewed new invoice
function* userViewDokumentSaga({ payload }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.userViewDokument, companyId, payload)
  } catch (error) {
    // do nothing
  }
}

// status check
function* statusCheckSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.statusCheck, companyId)
    yield put(actions.statusCheck.success(response.data))
  } catch (error) {
    // const errorMsg = getErrorMessage(error)
    yield put(actions.statusCheck.failure())
  }
}

function* bulkAiRecognitionSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.DOKUMENTS_AI_RECOGNITION,
      payload,
      {} // do not use filters yet
    )
    const response = yield call(api.dokumentBackgroundAction, companyId, apiPayload)
    // every selected items are removed clear selection
    if (response.data.status === JOB_STATUS_FINISHED) {
      // only trigger selection clear when no rejected items
      yield put(actions.bulkAiRecognition.success())
    }
    yield call(resolve, response.data)
  } catch (error) {
    const errorWithConsent = getErrorMessageWithConsent(error, 'hasDocumentAiConsent')
    yield call(reject, errorWithConsent)
  }
}

//* Filing
function* fetchLastFilingNumberSaga({ meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchLastFilingNumber, companyId)
    yield call(resolve, response.data.last)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* filingSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const apiPayload = generateBackgroundProcessActionPayload(BackgroundProcessActions.FILE_DOCUMENTS, payload, {})
    const response = yield call(api.dokumentBackgroundAction, companyId, apiPayload)
    yield put(actions.dokumentFiling.success(response.data.results))
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* bulkFilingSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getDokumentsFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(BackgroundProcessActions.FILE_DOCUMENTS, payload, filters)
    const response = yield call(api.dokumentBackgroundAction, companyId, apiPayload)
    //! call failure when need to update list, call succes when need to update items in store only
    if (payload.isAllSelected) {
      yield put(actions.bulkFiling.failure())
    } else {
      yield put(actions.bulkFiling.success(response.data.results))
    }
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  // list page controller init
  yield takeLatest(actions.initDokumentListPageLoad.REQUEST, initDokumentListPageLoadSaga)
  // list | listen on pagination options change | side effect list reload
  yield takeLatest(actions.fetchDokuments.REQUEST, fetchDokumentListV2Saga)
  yield takeLatest(
    [
      actions.updateRowsPerPage.REQUEST,
      actions.updateOrder.REQUEST,
      actions.bulkTagging.SUCCESS,
      actions.bulkRemoveDokuments.SUCCESS,
      actions.createWeblink.SUCCESS,
      filtersActions.updateDokumentFilters.REQUEST,
      filtersActions.resetDokumentFilters.REQUEST,
      filtersActions.toggleDokumentDateFilter.REQUEST,
      actions.attachDokuments.SUCCESS,
      actions.resetPagination.REQUEST,
      actions.refreshPage.REQUEST,
      actions.bulkAiRecognition.SUCCESS,
      actions.bulkFiling.FAILURE, //! used as secondary success action in this case
    ],
    fetchDokumentListSimpleSaga
  )
  // cursor pagination
  yield takeLatest(actions.fetchDokumentsByPaging.REQUEST, fetchDokumentsByPagingSaga)
  // FEATURES
  yield takeLatest(actions.createWeblink.REQUEST, createWeblinkSaga)
  // bulk remove
  yield takeLatest(actions.bulkRemoveDokuments.REQUEST, bulkRemoveDokumentsSaga)
  // tagging
  yield takeLatest(actions.bulkTagging.REQUEST, bulkTaggingSaga)
  // processes
  yield takeLatest(actions.downloadDokuments.REQUEST, downloadDokumentsSaga)
  yield takeLatest(actions.abortDownloadDokuments.REQUEST, abortDownloadDokumentsSaga)
  yield takeLatest(actions.bulkUploadDokuments.REQUEST, uploadDokumentsSaga)
  // actions
  yield takeLatest(actions.downloadDokument.REQUEST, downloadDokumentSaga)
  yield takeLatest(actions.renameDokument.REQUEST, renameDokumentSaga)
  yield takeLatest(actions.searchExpenses.REQUEST, searchExpensesSaga)
  yield takeLatest(actions.searchExpensesByUrl.REQUEST, searchExpensesByUrlSaga)
  yield takeLatest(actions.searchPartners.REQUEST, searchPartnersSaga)
  yield takeLatest(actions.searchIncomes.REQUEST, searchIncomesSaga)
  yield takeLatest(actions.searchIncomesByUrl.REQUEST, searchIncomesByUrlSaga)
  yield takeLatest(actions.searchDokuments.REQUEST, searchDokumentsSaga)
  yield takeLatest(actions.searchDokumentsByUrl.REQUEST, searchDokumentsByUrlSaga)

  yield takeLatest(actions.detachDokument.REQUEST, detachDokumentSaga)
  // details
  yield takeLatest(actions.fetchDokumentDetailsByPaging.REQUEST, fetchDokumentDetailsByPagingSaga)
  yield takeLatest(actions.fetchDokumentDetails.REQUEST, fetchDokumentDetailsSaga)
  yield takeLatest(actions.updateDokumentDetails.REQUEST, updateDokumentDetailsSaga)
  yield takeLatest(actions.fetchDokumentTypes.REQUEST, fetchDokumentTypesSaga)
  yield takeLatest(actions.createDokumentType.REQUEST, createDokumentTypeSaga)
  yield takeLatest(actions.removeDokument.REQUEST, removeDokumentSaga)
  // AI recognition
  yield takeLatest(actions.startAiRecognition.REQUEST, startAiRecognitionSaga)
  yield takeLatest(actions.continueAiRecognition.REQUEST, continueAiRecognitionSaga)
  yield takeLatest(actions.stopAiRecognition.REQUEST, stopAiRecognitionSaga)
  yield takeLatest(actions.userViewDokument.REQUEST, userViewDokumentSaga)
  // status check
  yield takeLatest([actions.statusCheck.REQUEST, actions.bulkAiRecognition.SUCCESS], statusCheckSaga)
  yield takeLatest(actions.bulkAiRecognition.REQUEST, bulkAiRecognitionSaga)
  // filing
  yield takeLatest(actions.fetchLastFilingNumber.REQUEST, fetchLastFilingNumberSaga)
  yield takeLatest(actions.dokumentFiling.REQUEST, filingSaga)
  yield takeLatest(actions.bulkFiling.REQUEST, bulkFilingSaga)
}
