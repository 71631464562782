import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { CommonAxiosPayload, paymentActions } from '@services'

import { bindActionToPromise, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch } from '@contexts/AlertProvider'

import { SpecialDownload, SpecialDownloadRenderProps } from './SpecialDownload'

interface PaymentExportRenderProps
  extends Optional<SpecialDownloadRenderProps, 'aria-controls' | 'aria-haspopup' | 'aria-expanded'> {
  downloading: boolean
}

interface PaymentExportProps {
  callExportPaymentUrl: AsyncFunction<
    CommonAxiosPayload<{
      transfer_type: string | undefined
    }>
  >
  children: (renderProps: PaymentExportRenderProps) => JSX.Element
  methodUrl: string
  onDownloadSuccess?: VoidFunction
  requireTransferTypeSelection: boolean
  transferTypeOptions: Nullable<Array<{ name: string; transferType: string }>>
}

function PurePaymentExport({
  callExportPaymentUrl,
  children,
  methodUrl,
  onDownloadSuccess,
  requireTransferTypeSelection,
  transferTypeOptions,
}: PaymentExportProps) {
  const [downloading, setDownloading] = React.useState(false)
  const { setErrorAlert } = useAlertDispatch()

  async function downloadHandler(transfer_type?: string) {
    if (methodUrl) {
      setDownloading(true)
      // this is not handled as a background process so we do not trigger info alert here
      try {
        await callExportPaymentUrl({
          method: 'post',
          url: methodUrl,
          data: { transfer_type },
        })
        onDownloadSuccess?.()
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          setErrorAlert(String(errorMessage), { autoHideDuration: 10000 })
        }
      }
      setDownloading(false)
    }
  }

  function onDownload() {
    downloadHandler()
  }

  // when a provider requires specific transfer type data, like OTP does, we need the user to make a selection before downloading
  if (requireTransferTypeSelection && transferTypeOptions) {
    return (
      <SpecialDownload onDownload={downloadHandler} options={transferTypeOptions}>
        {props => children({ downloading, ...props })}
      </SpecialDownload>
    )
  }

  return children({ downloading, onClick: onDownload })
}

PurePaymentExport.propTypes = {
  methodUrl: PropTypes.string.isRequired,
  onDownloadSuccess: PropTypes.func,
  requireTransferTypeSelection: PropTypes.bool.isRequired,
  transferTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string.isRequired, transferType: PropTypes.string.isRequired }).isRequired
  ) as React.Validator<PaymentExportProps['transferTypeOptions']>,
}

export const PaymentExport = connect(null, dispatch => ({
  callExportPaymentUrl: bindActionToPromise(dispatch, paymentActions.exportPayment.request),
}))(PurePaymentExport)

PaymentExport.displayName = 'PaymentExport'
