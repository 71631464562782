import React from 'react'

import { useCalculationBaseControl } from '@contexts/CalculationBaseControlProvider'

import { PartnerSearchField } from './PartnerSearchField'
import { PartnerSearchFieldProps } from './types'

type PartnerSearchFieldWithCalcualtionBaseProps = Omit<
  PartnerSearchFieldProps,
  'calculationBase' | 'setCalculationBase' | 'company' | 'searchPartners'
>

export function PartnerSearchFieldWithCalculationBase({
  defaultValue,
  ...rest
}: PartnerSearchFieldWithCalcualtionBaseProps) {
  const { calculationBase, setCalculationBase } = useCalculationBaseControl()

  //* CalculationBase initialization
  React.useEffect(() => {
    setCalculationBase?.(defaultValue?.calculation_base ?? 'gross_amount')
  }, [defaultValue, setCalculationBase])

  return (
    <PartnerSearchField
      {...rest}
      calculationBase={calculationBase}
      defaultValue={defaultValue}
      setCalculationBase={setCalculationBase}
    />
  )
}
