import React from 'react'

import { FormattedMessage } from 'react-intl'

export const SecondaryIdTableHeaderMessage = (
  <FormattedMessage id="payment.table.th.secondaryId" defaultMessage="Másodlagos azonosító" />
)

export const PartnerTableHeaderMessage = <FormattedMessage id="payment.table.th.partnerName" defaultMessage="Partner" />

export const BankAccountNumberTableHeaderMessage = (
  <FormattedMessage id="common.table.th.accountNumber" defaultMessage="Bankszámlaszám" />
)

export const InvoiceNumberTableHeaderMessage = (
  <FormattedMessage id="common.table.th.invoiceNumber" defaultMessage="Számla sorszáma" />
)

export const AmountTableHeaderMessage = <FormattedMessage id="common.table.th.amount" defaultMessage="Összeg" />

export const TagsTableHeaderMessage = <FormattedMessage id="partner.table.th.invoiceTags" defaultMessage="Címkék" />

export const DueAtTableHeaderMessage = <FormattedMessage id="invoice.table.th.dueAt" defaultMessage="Esedékesség" />

export const TaxNumberTableHeaderMessage = (
  <FormattedMessage id="partner.table.th.tax_number" defaultMessage="Adószám" />
)

export const FilingNumberTableHeaderMessage = (
  <FormattedMessage id="partner.table.th.invoiceFilingNumber" defaultMessage="Iktatószám" />
)

export const StatusesTableHeaderMessage = <FormattedMessage id="partner.table.th.statuses" defaultMessage="Státuszok" />

export const DokumentNameTableHeaderMessage = (
  <FormattedMessage id="document.table.th.documentName" defaultMessage="Dokumentum név" />
)

export const DokumentKeywordsTableHeaderMessage = (
  <FormattedMessage id="document.table.th.keywords" defaultMessage="Kulcsszavak" />
)

export const DokumentsCreatedAtTableHeaderMessage = (
  <FormattedMessage id="documents.table.th.createdAt" defaultMessage="Feltöltés ideje" />
)
