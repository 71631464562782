import React from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { CreatePaymentFormData, CreatePaymentPayload } from '@contexts/PaymentProvider'

import {
  Button,
  ReactHookForm,
  ReactHookFormDatePickerField,
  ReactHookFormError,
  ReactHookFormSubmitButton,
} from '@components/ui'

import { EXPRESS_PAID_THROUGH_NAME, PAYMENT_FLOW } from '@constants'

import { CreateManualOrExpressPaymentFormProps, ManualOrExpensePaymentFormValues } from '../types'
import { BalanceAllTransactionsButton } from './BalanceAllTransactionsButton'
import { ExpressPaymentSwitch } from './ExpressPaymentSwitch'
import { PaidThroughField } from './fields'
import { getToday } from './helpers'
import { FAKE_OPTION_ID } from './PaidThroughBalance'
import { PaymentHeader } from './PaymentFormElements'
import { PaymentInformationalMessage } from './PaymentInformationalMessage'
import { PaymentTransactionsFieldArrayTable } from './PaymentTransactionsFieldArrayTable'
import { useExpressValidationSchema, useManualValidationSchema } from './validators'

import { CancelButtonMessage, globalMessages } from '@messages'
import { FormDialogBody } from '@components/FormDialogs/styles'
import { DatePickerLabelMessage } from './messages'
import {
  ExpressPaymentFieldset,
  PaymentDialogActions,
  PaymentDialogBodyWrapper,
  SameSizeButtonsContainer,
  StyledTransactionsTotalAmount,
} from './styles'

function getInitialPaidThroughId(
  companyDefaultPaidThroughId: Nullable<number>,
  paidThroughOptions: PaidThroughOption[]
) {
  let paidThroughId = null
  // only prefill with companyDefaultPaidThroughId if it has "bank" type and exits in paidThroughOptions
  const defaultOption = paidThroughOptions.find(({ id }) => id === companyDefaultPaidThroughId)
  if (defaultOption) {
    paidThroughId = defaultOption.id
  } else if (paidThroughOptions.length === 1) {
    paidThroughId = paidThroughOptions[0].id
  }

  return paidThroughId
}

function useInitialValues(
  transactions: PaymentSetupResponseData['transactions'],
  companyDefaultPaidThroughId: Nullable<number>,
  flow: PaymentFlowType,
  paidThroughOptions: PaidThroughOption[]
) {
  const initialPaidThroughIdRef = React.useRef<Nullable<number>>(
    getInitialPaidThroughId(companyDefaultPaidThroughId, paidThroughOptions)
  )

  return React.useMemo<ManualOrExpensePaymentFormValues>(() => {
    let paidThroughId = null
    if (flow !== PAYMENT_FLOW.express) {
      paidThroughId = initialPaidThroughIdRef.current
    }

    return {
      date: getToday(),
      paidThroughId: paidThroughId,
      transactions: transactions.map(({ amount, remaining, transactionId }) => ({
        amount: flow === PAYMENT_FLOW.express ? remaining : amount || '',
        transactionId,
      })),
    }
  }, [flow, transactions])
}

/**
 * Create a virtual "házipénztár" option and filter out all "cash" type options
 * @param paidThroughOptions - paid through options from backend
 * @returns new options list
 */
function useManualFormPaidThroughOptions(paidThroughOptions: PaidThroughOption[]) {
  const { formatMessage } = useIntl()

  return React.useMemo(() => {
    const virtualManualOption: PaidThroughOption = {
      id: FAKE_OPTION_ID, // fake id
      name: formatMessage(globalMessages.pettyCash),
      paidthrough_type: 1, // cash
      provider: null,
      provider_name: null,
    }

    return [...paidThroughOptions, virtualManualOption]
  }, [formatMessage, paidThroughOptions])
}

export function PureCreateManualOrExpressPaymentForm({
  companyDefaultPaidThrough,
  data: { transactions },
  flow,
  setFlow,
  onClose,
  onSubmit,
  onSubmitSuccess,
  paidThroughOptions,
  showInfoRow,
  transactionType,
}: CreateManualOrExpressPaymentFormProps) {
  const isExpressPayment = flow === PAYMENT_FLOW.express
  const expressValidationSchema = useExpressValidationSchema()
  const manualValidationSchema = useManualValidationSchema()
  const manualOptions = useManualFormPaidThroughOptions(paidThroughOptions)
  const initialValues = useInitialValues(transactions, companyDefaultPaidThrough, flow, paidThroughOptions)

  function handleFlowChange() {
    setFlow(flow === PAYMENT_FLOW.express ? PAYMENT_FLOW.manual : PAYMENT_FLOW.express)
  }

  function onSubmitHandler({ date, transactions, paidThroughId }: CreatePaymentFormData) {
    const payload: CreatePaymentPayload = {
      date,
      transactions,
      paidThroughId,
      transactionType,
      flow,
      paidThroughType: null,
    }

    if (flow === PAYMENT_FLOW.manual) {
      const paidThroughType = manualOptions.find(({ id }) => id === paidThroughId)?.paidthrough_type

      if (!paidThroughType) {
        throw Error('Unexpected error - missing paidThroughType')
      }

      payload.paidThroughType = paidThroughType
      if (paidThroughType === 1) {
        payload.paidThroughId = null
      }
    }

    return onSubmit(payload)
  }

  const submitButtonText = isExpressPayment ? (
    <FormattedMessage id="payment.dialog.buttons.pay" defaultMessage="Fizetés" />
  ) : (
    <FormattedMessage id="payment.dialog.buttons.record" defaultMessage="Rögzítés" />
  )

  return (
    <ReactHookForm
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      onSubmitSuccess={onSubmitSuccess}
      validationSchema={isExpressPayment ? expressValidationSchema : manualValidationSchema}
    >
      <FormDialogBody>
        <PaymentDialogBodyWrapper>
          {showInfoRow && <PaymentInformationalMessage transactionType={transactionType} />}
          <ExpressPaymentSwitch checked={isExpressPayment} initialValues={initialValues} onChange={handleFlowChange} />
          <ExpressPaymentFieldset disabled={isExpressPayment}>
            <PaymentHeader
              button={<BalanceAllTransactionsButton transactions={transactions} />}
              datepicker={
                <ReactHookFormDatePickerField
                  name="date"
                  label={DatePickerLabelMessage}
                  required
                  disabled={isExpressPayment}
                />
              }
              paidThroughSelect={
                <PaidThroughField
                  disabled={isExpressPayment}
                  options={manualOptions}
                  {...(isExpressPayment && { placeholder: EXPRESS_PAID_THROUGH_NAME })}
                />
              }
            />
            <PaymentTransactionsFieldArrayTable
              disabled={isExpressPayment}
              flow={flow}
              transactions={transactions}
              transactionType={transactionType}
            />
            <StyledTransactionsTotalAmount transactions={transactions} />
          </ExpressPaymentFieldset>
        </PaymentDialogBodyWrapper>
        <PaymentDialogActions borderless>
          <ReactHookFormError />
          <SameSizeButtonsContainer>
            <Button onClick={onClose} variant="secondaryContained" type="button">
              {CancelButtonMessage}
            </Button>
            <ReactHookFormSubmitButton buttonText={submitButtonText} isCreateOnly />
          </SameSizeButtonsContainer>
        </PaymentDialogActions>
      </FormDialogBody>
    </ReactHookForm>
  )
}

export const CreateManualOrExpressPaymentForm = connect((state: Store) => ({
  companyDefaultPaidThrough: state.auth.company.data.default_paid_through,
  paidThroughOptions: state.payment.paidThroughOptions.filter(
    ({ name, paidthrough_type }) => paidthrough_type === 2 && name !== EXPRESS_PAID_THROUGH_NAME
  ), // only bank type allowed here
}))(PureCreateManualOrExpressPaymentForm)

CreateManualOrExpressPaymentForm.displayName = 'CreateManualOrExpressPaymentForm'
