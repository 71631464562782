import React from 'react'

import { PopperProps } from '@material-ui/core'
import { useIntl } from 'react-intl'

import { PreviewVariant, usePreviewOpenHandler } from '@contexts/PreviewProvider'

import { InvoicePreviewVariant } from '@components/GeneratedPreviewViewer'
import { EyeIcon, IconButton } from '@components/ui'
import { LightTooltip } from '@oldComponents/ui'

import { messages } from './messages'

interface InvoicePreviewOpenerProps {
  detailsUrl?: string
  disablePortal?: boolean
  invoiceId: ItemIdType
  invoiceVariant: InvoicePreviewVariant
  placement?: PopperProps['placement']
  previewUrl: string
  previewVariant: PreviewVariant
  supportedMessage?: string
}

export function InvoicePreviewOpener({
  detailsUrl,
  disablePortal = true,
  placement,
  previewVariant,
  supportedMessage,
  ...rest
}: InvoicePreviewOpenerProps) {
  const { formatMessage } = useIntl()
  const { handleOpenWithInvoice } = usePreviewOpenHandler()

  const handleOpen = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()
      handleOpenWithInvoice(rest, previewVariant, detailsUrl)
    },
    [detailsUrl, handleOpenWithInvoice, previewVariant, rest]
  )

  return (
    <LightTooltip
      title={supportedMessage ?? formatMessage(messages.invoiceSupportedMessage)}
      PopperProps={{ disablePortal, placement }}
    >
      <IconButton onClick={handleOpen} size="small">
        <EyeIcon size={20} />
      </IconButton>
    </LightTooltip>
  )
}
