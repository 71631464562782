import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { FormattedMessage } from 'react-intl'

import { LightTooltip } from '@oldComponents/ui/LightTooltip'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.grayscale.inputLabel,
    display: 'inline-flex',
    cursor: 'help',
    alignItems: 'center',
    width: 22,
  },
}))

/**
 * Simple component to display a tooltip with a message when a payment is not editable.
 */
export default function NotEditablePaymentTooltip() {
  const classes = useStyles()
  return (
    <LightTooltip
      title={
        <FormattedMessage
          id="paymentDetails.permission.tooltip"
          defaultMessage="Ebben az utalási csomagban számodra nem látható tételek is vannak, ezért nem szerkesztheted ezt."
        />
      }
      placement="left"
    >
      <span className={classes.root} data-testid="not-editable-info">
        <InfoIcon style={{ fontSize: 20 }} />
      </span>
    </LightTooltip>
  )
}
