import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { CommonAxiosPayload } from '@services'

import { cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch } from '@contexts/AlertProvider'
import { useSuccessDialog } from '@contexts/SuccessDialogProvider'

import { useCancellablePromiseRef } from '@hooks'

import { AsyncButton, SimpleDialog } from '@components/ui'

import { DIALOG_CLASS_NAMES } from '@constants/dialog'

import { DeleteButtonMessage, OptionNoMessage, OptionYesMessage } from '@messages'

const StyledSimpleDialog = styled(SimpleDialog)`
  &.${DIALOG_CLASS_NAMES.content.base} {
    --gap: 20px;
    gap: 40px;
    width: 100%;
  }
`

interface DeleteTaskButtonProps {
  callUrl: AsyncFunction<CommonAxiosPayload, unknown>
  description?: StringOrMessage
  refetchReports: VoidFunction
  url: string
}

export function DeleteTaskButton({ callUrl, description, refetchReports, url }: DeleteTaskButtonProps) {
  const [isDialogOpen, setDialogOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const { setErrorAlert } = useAlertDispatch()
  const setSuccessMessage = useSuccessDialog()
  const cPromiseRef = useCancellablePromiseRef()

  function handleDialogOpen() {
    setDialogOpen(true)
  }

  function handleDialogClose() {
    setDialogOpen(false)
  }

  async function handleDelete() {
    setLoading(true)
    setDialogOpen(false)
    try {
      cPromiseRef.current = cancellablePromise(callUrl({ url, method: 'delete' }))
      await cPromiseRef.current.promise
      // success message
      setSuccessMessage({
        title: <FormattedMessage id="taxation.dialogs.deleteSuccess.title" defaultMessage="Feladat törölve" />,
        message: (
          <FormattedMessage
            id="taxation.dialogs.deleteSuccess.message"
            defaultMessage="Sikeresen törölted ezt a feladatot és annak tartalmát. Ha szeretnéd újragenerálni ezt a kártyát, akkor vedd fel a kapcsolatot ügyfélszolgálatunkkal."
          />
        ),
      })
      // refetch pulzus
      refetchReports()
    } catch (error) {
      const errorMsg = parseApiErrorMessage(error)
      if (errorMsg) {
        setErrorAlert(errorMsg)
      }
    }

    setLoading(false)
  }

  return (
    <>
      <AsyncButton onClick={handleDialogOpen} variant="alert" loading={loading}>
        {DeleteButtonMessage}
      </AsyncButton>

      <StyledSimpleDialog
        open={isDialogOpen}
        description={
          description ?? (
            <FormattedMessage
              id="dialogs.taxation.deleteConfirm.description"
              defaultMessage="A feladat törlésével a Pulzusból törlődik ez a kártya és nem írunk elő adókat."
            />
          )
        }
        onClose={handleDialogClose}
        onPrimaryAction={handleDialogClose}
        onSecondaryAction={handleDelete}
        primaryActionText={OptionNoMessage}
        secondaryActionText={OptionYesMessage}
        title={
          <FormattedMessage
            id="dialogs.taxation.deleteConfirm.title"
            defaultMessage="Biztosan törlöd ezt a feladatot?"
          />
        }
      />
    </>
  )
}

DeleteTaskButton.propTypes = {
  callUrl: PropTypes.func.isRequired,
  description: PropTypes.node,
  refetchReports: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
}
