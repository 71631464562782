import React from 'react'

import styled from 'styled-components'

import { useCalculationBaseControl } from '@contexts/CalculationBaseControlProvider'

import { CalculatorIcon } from '@components/ui'
import { LightTooltip } from '@oldComponents/ui'

const StyledCalculatorIcon = styled(CalculatorIcon)`
  color: ${({ theme }) => theme.colors.primary[50]};
`

const IconWrapperSpan = styled.span`
  display: flex;
  align-items: center;
`

interface CalculationBaseInputIndicatorProps {
  tooltip: StringOrMessage
  type: CalculationBase
}

export function CalculationBaseInputIndicator({ tooltip, type }: CalculationBaseInputIndicatorProps) {
  const { calculationBase } = useCalculationBaseControl()

  if (calculationBase !== type) {
    return null
  }

  return (
    <LightTooltip title={tooltip}>
      <IconWrapperSpan>
        <StyledCalculatorIcon size={14} />
      </IconWrapperSpan>
    </LightTooltip>
  )
}
