// Role-Based Access Control Rules

// const exampleRules = {
//   driver: {
//     static: ['home-page:driver-viewset', 'driver-page:link', 'driver-page:visit'],
//     dynamic: {
//       'posts:edit': ({ userId, postOwnerId }) => {
//         if (!userId || !postOwnerId) return false
//         return userId === postOwnerId
//       },
//     },
//   },
// }

//! subscription PLANS controls company level permissions
// "grandfather_monthly",
// "grandfather_yearly",
// "base_monthly",
// "base_yearly",
// "finance_monthly",
// "finance_yearly",
// "pro_monthly",
// "pro_yearly",

export enum FeaturePermissons {
  BANK_TRANSACTIONS = 'feature:bank-transactions', // paid-through balance visibility
  CATEGORIZATION = 'feature:categorization',
  COMPANY_USER_PERMISSIONS = 'feature:company-user-permissions',
  DETAILS_BULK_UPDATE = 'feature:details-bulk-update', // on details update: bulk vat_area, paid_through, tags, simple_tags, expense_type copy
  EXPENSE_DETAILS_DUE_AT = 'feature:expense-details-due_at',
  KATA_PARTNERS = 'feature:kata-partners',
  NOSZ = 'feature:nosz',
  TAG = 'feature:tag',
  //* Company advanced features
  ADVANCED_ACCOUNTING = 'advanced_accounting', // non public setting and available in all tiers
  ADVANCED_APPROVAL = 'advanced_approval', // only available in pro tier
  REQUIRE_APPROVAL_FOR_PAYMENT = 'require_approval_for_payments', // only available in pro tier
  EXPENSE_CUSTOM_FIELDS = 'feature:expense-custom-fields',
}

export enum PagePermissions {
  DETAILS_ATTACHMENT = 'page:details-attachment',
  DOKUMENTS = 'page:dokuments',
  LIQUIDITY = 'page:liquidity',
  MONTHLY_STATEMENT = 'page:monthly-statement',
  PAYMENTS = 'page:payments',
}

const baseCommonPermissions = [PagePermissions.PAYMENTS, FeaturePermissons.ADVANCED_ACCOUNTING]

// TODO: why are we not basing off of basePermissions? EXPENSE_DETAILS_DUE_AT is used explicitly in ExpenseDetailsForm
const basePermissions = [...baseCommonPermissions, FeaturePermissons.EXPENSE_DETAILS_DUE_AT]
const financePermissions = [
  ...baseCommonPermissions,
  FeaturePermissons.BANK_TRANSACTIONS,
  FeaturePermissons.CATEGORIZATION,
  FeaturePermissons.DETAILS_BULK_UPDATE,
  FeaturePermissons.KATA_PARTNERS,
  FeaturePermissons.NOSZ,
  FeaturePermissons.TAG,
  PagePermissions.DETAILS_ATTACHMENT,
  PagePermissions.LIQUIDITY,
  PagePermissions.MONTHLY_STATEMENT,
]
const proPermissions = [
  ...financePermissions,
  FeaturePermissons.COMPANY_USER_PERMISSIONS,
  PagePermissions.DOKUMENTS,
  FeaturePermissons.ADVANCED_APPROVAL,
  FeaturePermissons.REQUIRE_APPROVAL_FOR_PAYMENT,
  FeaturePermissons.EXPENSE_CUSTOM_FIELDS,
]

export const COMPANY_PLAN_TIERS = {
  pro: {
    static: proPermissions,
  },
  finance: {
    static: financePermissions,
  },
  base: {
    static: basePermissions,
  },
} as const
