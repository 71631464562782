import React from 'react'

import { connect } from 'react-redux'

import { filtersActions } from '@services'

import { usePageConfig } from '@contexts/PageControllerProvider'
import { usePortal } from '@contexts/PortalProvider'

import { FilterBar, FilterBarBaseProps } from './FilterBar'

interface PurePartnerListPageFilterBarProps extends FilterBarBaseProps {
  filters: PartnerListStoreFilters
}

function PurePartnerListPageFilterBar({
  filters: { dateField, search, searchFields },
  isDateFilterDisabled,
  resetFilters,
  updateFilters,
}: PurePartnerListPageFilterBarProps) {
  const { setPortalAnchorEl } = usePortal()
  const pageConfig = usePageConfig()

  const searchFilterProps = {
    search,
    searchFields,
    options: pageConfig.searchFilterProps?.config.options ?? [],
  }

  return (
    <FilterBar
      dateField={dateField}
      isDateFilterDisabled={isDateFilterDisabled}
      isDateFilterToggleDisabled
      isDateFilterUsed
      resetFilters={resetFilters}
      searchFilterProps={searchFilterProps}
      setPortalRef={setPortalAnchorEl}
      updateFilters={updateFilters}
    />
  )
}

export const PartnerListPageFilterBar = connect(
  ({ filters: { isGlobalDateFilterDisabled, partnerList } }: Store) => {
    return {
      filters: partnerList,
      isDateFilterDisabled: isGlobalDateFilterDisabled,
    }
  },
  {
    resetFilters: filtersActions.resetPartnerListFilters.request,
    updateFilters: filtersActions.updatePartnerListFilters.request,
  }
)(PurePartnerListPageFilterBar)

PartnerListPageFilterBar.displayName = 'PartnerListPageFilterBar'
