import React from 'react'

import { defineMessage, useIntl } from 'react-intl'

import { EMPTY_CUSTOM_FIELD_OPTION_VALUE } from '@constants'

const CustomFieldFilterEmptySelectOptionMessage = defineMessage({
  id: 'filters.customField.select.emptyOption',
  defaultMessage: 'Nincs kitöltve',
})

export function useCustomFieldsFilterProps(
  config: Nullable<ExpenseCustomFieldConfig[]>
): { config: CustomFieldsFilterConfig } | undefined {
  const { formatMessage } = useIntl()

  return React.useMemo(() => {
    if (!config) {
      return
    }

    return {
      config: {
        name: 'customFields',
        fields: config.map(fieldConfig => ({
          fieldName: fieldConfig.field_name,
          label: fieldConfig.label,
          options: [
            ...fieldConfig.options,
            { value: EMPTY_CUSTOM_FIELD_OPTION_VALUE, label: formatMessage(CustomFieldFilterEmptySelectOptionMessage) },
          ],
        })),
      },
    }
  }, [config, formatMessage])
}
