import React from 'react'

import { InfoIcon, ReactHookFormSelectField } from '@components/ui'
import { LightTooltip } from '@oldComponents/ui'

import { TagIconContainerSpan } from '../styles'

interface DynamicFieldProps {
  config: ExpenseCustomFieldConfig
  disabled: boolean
  name: string
}

export function DynamicField({
  config: { label, options, description, is_required },
  disabled,
  name,
}: DynamicFieldProps) {
  return (
    <ReactHookFormSelectField
      disabled={disabled}
      isClearable={!is_required}
      label={
        description ? (
          <LightTooltip title={description} placement="top">
            <TagIconContainerSpan>
              {label}
              <InfoIcon size={16} />
            </TagIconContainerSpan>
          </LightTooltip>
        ) : (
          label
        )
      }
      labelKey="label"
      name={name}
      options={options}
      required={is_required}
      valueKey="value"
    />
  )
}
