import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { incomeActions } from '@services'

import { bindActionToPromise, cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch } from '@contexts/AlertProvider'

import { useCancellablePromiseRef } from '@hooks'

import { AsyncButtonWithIcon, DownloadArrowIcon } from '@components/ui'

interface IncomeRegisterDownloadButtonProps {
  callIncomeRegisterDownload: (payload: { startDate: string; endDate: string }) => Promise<void>
  startDate: string
  endDate: string
}

function PureIncomeRegisterDownloadButton({
  callIncomeRegisterDownload,
  startDate,
  endDate,
}: IncomeRegisterDownloadButtonProps) {
  const [loading, setLoading] = React.useState(false)
  const { setErrorAlert, setSuccessAlert } = useAlertDispatch()
  const cPromiseRef = useCancellablePromiseRef()
  const { formatDate } = useIntl()

  async function handleClick() {
    setLoading(true)
    try {
      cPromiseRef.current = cancellablePromise(callIncomeRegisterDownload({ startDate, endDate }))
      await cPromiseRef.current.promise
      setLoading(false)
      setSuccessAlert(<FormattedMessage id="alert.download.successMessage" defaultMessage="Sikeres letöltés" />)
    } catch (error) {
      const errorMsg = parseApiErrorMessage(error)
      if (errorMsg) {
        setLoading(false)
        setErrorAlert(errorMsg)
      }
    }
  }

  return (
    <AsyncButtonWithIcon
      loading={loading}
      icon={<DownloadArrowIcon />}
      variant="primaryText"
      onClick={handleClick}
      disabled={loading}
    >
      {`${formatDate(startDate)} - ${formatDate(endDate)}`}
    </AsyncButtonWithIcon>
  )
}

PureIncomeRegisterDownloadButton.propTypes = {
  callIncomeRegisterDownload: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
}

export const IncomeRegisterDownloadButton = connect(null, dispatch => ({
  callIncomeRegisterDownload: bindActionToPromise(dispatch, incomeActions.incomeRegisterDownload.request),
}))(PureIncomeRegisterDownloadButton)

IncomeRegisterDownloadButton.displayName = 'IncomeRegisterDownloadButton'
