import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { expenseActions } from '@services'

import { bindActionToPromise, userHasEditorPermission } from '@helpers'

import { useAlertDispatch } from '@contexts/AlertProvider'
import {
  useExpenseDetailsControlsDispatch,
  useExpenseDetailsControlsState,
} from '@contexts/ExpenseDetailsControlsProvider'

import { useFilingAction, UseFilingActionProps } from '@hooks'

import { QuickUserFeaturePermissions } from '@constants'

import { permissionDeniedForUser } from '@permissions'

import { DetailsFiling } from './DetailsFiling'

interface ExpenseDetailsFilingProps
  extends Pick<UseFilingActionProps<ExpenseFilingResponse>, 'callFiling' | 'callLastFilingNumber'> {
  actionEnabled: boolean
  filingNumber?: Nullable<string>
  isEditorUser: boolean
  itemId?: ItemIdType
}

function PureExpenseDetailsFiling({
  actionEnabled,
  callFiling,
  callLastFilingNumber,
  filingNumber,
  isEditorUser,
  itemId,
}: ExpenseDetailsFilingProps) {
  const { setErrorAlert } = useAlertDispatch()
  const { isActionInProgress } = useExpenseDetailsControlsState()
  const { setFilingInProgress } = useExpenseDetailsControlsDispatch()

  function handleResponse({ errors: { denied } }: ExpenseFilingResponse) {
    if (denied.length > 0) {
      setErrorAlert(
        <FormattedMessage
          id="expense.details.filingError"
          defaultMessage="Sajnáljuk! A számlán az iktatás nem végrehajtható, amíg összerendelésre vár egy NAV-os számlával vagy duplikáció. Kérünk végezd el az összerendelést vagy szüntesd meg a duplikációt."
        />,
        { autoHideDuration: 8000 }
      )
    }
  }

  const { handleClose, handleFiling, handleOpen, lastKnownFilingNumber, loading, open } = useFilingAction({
    callFiling,
    callLastFilingNumber,
    handleResponse,
    payload: { ids: [itemId as ItemIdType], isAllSelected: false },
    setFilingInProgress,
  })

  if (!itemId) {
    console.error('Unexpected error: filing action is missing itemId!')
    return null
  }

  return (
    <DetailsFiling
      dialogInfoMessage={
        <FormattedMessage
          id="dialogs.filing.expense.confirmText"
          defaultMessage="A legutolsó kiosztott iktatószám a(z) {value} volt. Ha ezt a számlát iktatod, akkor később már nem fogod tudni visszavonni ezt a műveletet."
          values={{ value: <strong>{lastKnownFilingNumber}</strong> }}
        />
      }
      disabled={isActionInProgress}
      handleClose={handleClose}
      handleFiling={handleFiling}
      handleOpen={handleOpen}
      isActionAvailable={actionEnabled && !filingNumber && isEditorUser}
      loading={loading}
      open={open}
    >
      {filingNumber ? (
        <FormattedMessage
          id="form.expense.text.filingNumber"
          defaultMessage="Ez a számla már iktatva van a(z) {value} iktatószámon."
          values={{ value: <strong>{filingNumber}</strong> }}
        />
      ) : (
        <FormattedMessage id="form.expense.text.filingNumber.notSet" defaultMessage="Ez a számla még nincs iktatva." />
      )}
    </DetailsFiling>
  )
}

PureExpenseDetailsFiling.propTypes = {
  actionEnabled: PropTypes.bool.isRequired,
  callFiling: PropTypes.func.isRequired,
  callLastFilingNumber: PropTypes.func.isRequired,
  filingNumber: PropTypes.string,
  isEditorUser: PropTypes.bool.isRequired,
  itemId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
}

export const ExpenseDetailsFiling = connect(
  (state: Store) => ({
    actionEnabled:
      !state.expense.details.data?.missing &&
      !permissionDeniedForUser(state, QuickUserFeaturePermissions.denyExpenseFilePermission),
    filingNumber: state.expense.details.data?.filing_number,
    isEditorUser: userHasEditorPermission(state.auth.company.data.role),
    itemId: state.expense.details.data?.id,
  }),
  dispatch => ({
    callFiling: bindActionToPromise(dispatch, expenseActions.expenseFiling.request),
    callLastFilingNumber: bindActionToPromise(dispatch, expenseActions.fetchLastFilingNumber.request),
  })
)(PureExpenseDetailsFiling)

ExpenseDetailsFiling.displayName = 'ExpenseDetailsFiling'
