import React from 'react'

import { Grid } from '@material-ui/core'
import __groupBy from 'lodash/groupBy'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { getMaximumFractionDigitsByCurrency, roundToDecimal } from '@helpers'

import { AssignmentsBreakdownButton } from './AssignmentsBreakdownButton'

const StyledGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-bottom: 15px;
`

//* local helper
function sumByField(items: InvoiceDetailsItem[], key: keyof InvoiceDetailsItem) {
  return items.reduce((sum, item) => (sum += Number(item[key]) ?? 0), 0)
}

interface AssignmentsBreakdownProps extends Record<string, unknown> {
  companyVats: CompanyVatType[]
  emptyValues: ExpenseDetailsAssignment | IncomeDetailsAssignment
  hasIntegration: boolean
  isEditDisabled: boolean
  isNavInvoice: boolean
  items: InvoiceDetailsItem[]
}

export function AssignmentsBreakdown({
  companyVats,
  emptyValues,
  hasIntegration,
  isEditDisabled,
  isNavInvoice,
  items,
}: AssignmentsBreakdownProps) {
  const { setValue, getValues } = useFormContext()

  const isEnabled = isNavInvoice || hasIntegration

  const vatMap = React.useMemo(
    () =>
      companyVats
        .filter(({ percent }) => percent !== null) // filter out mixed VAT option (percent: null)
        .reduce((vMap, vat) => ({ ...vMap, [vat.percent as number]: vat.id }), {} as Record<number, number>),
    [companyVats]
  )

  const breakdownHandler = React.useCallback(() => {
    const currency = getValues('currency')
    const maximumFractionDigits = getMaximumFractionDigitsByCurrency(currency)
    // when no items push at least one assignment
    let assignments = [emptyValues]

    if (items.length) {
      assignments = items.map(item => ({
        ...emptyValues,
        gross_amount: roundToDecimal(item.gross_amount, { maximumFractionDigits, numeric: false }),
        net_amount: roundToDecimal(item.net_amount, { maximumFractionDigits, numeric: false }),
        vat_amount: roundToDecimal(item.vat_amount, { maximumFractionDigits, numeric: false }),
        vat: item.vat_percent == null ? null : vatMap[item.vat_percent] ?? null,
      }))
    }

    setValue('assignments', assignments, { shouldDirty: true, shouldTouch: true })
  }, [emptyValues, getValues, items, setValue, vatMap])

  const breakdownByVatHandler = React.useCallback(() => {
    const currency = getValues('currency')
    const maximumFractionDigits = getMaximumFractionDigitsByCurrency(currency)
    // when no items push at least one assignment
    let assignments = [emptyValues]

    if (items.length) {
      const groups = __groupBy(items, 'vat_percent')

      assignments = Object.entries(groups).map(([vatKey, vatItems]) => ({
        ...emptyValues,
        gross_amount: roundToDecimal(sumByField(vatItems, 'gross_amount'), { maximumFractionDigits, numeric: false }),
        net_amount: roundToDecimal(sumByField(vatItems, 'net_amount'), { maximumFractionDigits, numeric: false }),
        vat_amount: roundToDecimal(sumByField(vatItems, 'vat_amount'), { maximumFractionDigits, numeric: false }),
        vat: vatMap[Number(vatKey)] ?? null,
      }))
    }

    setValue('assignments', assignments, { shouldDirty: true, shouldTouch: true })
  }, [emptyValues, getValues, items, setValue, vatMap])

  if (!isEnabled) {
    return null
  }

  return (
    <Grid container>
      <StyledGrid item xs={12}>
        <AssignmentsBreakdownButton
          data-testid="vat-breakdown-button"
          disabled={isEditDisabled}
          onClick={breakdownByVatHandler}
        >
          <FormattedMessage id="assignmentsBreakdown.buttons.vat" defaultMessage="ÁFA kulcsok szerinti tételbontás" />
        </AssignmentsBreakdownButton>
        <AssignmentsBreakdownButton
          data-testid="default-breakdown-button"
          disabled={isEditDisabled}
          onClick={breakdownHandler}
        >
          <FormattedMessage id="assignmentsBreakdown.buttons.default" defaultMessage="Automatikus tételbontás" />
        </AssignmentsBreakdownButton>
      </StyledGrid>
    </Grid>
  )
}
