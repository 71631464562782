import { AxiosResponse } from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import attachmentActions from '@services/attachment/actions'
import { fetchAttachments as fetchAttachmentsApi } from '@services/attachment/api'

import { generateAPIPayload, getActiveCompanyId, getDokumentsFiltersFromStore, getErrorMessage } from '@helpers'

import actions from './actions'
import * as api from './api'

function* attachDokumentsSaga({
  payload: { data, ...payload },
  meta: { resolve, reject },
}: AsyncSagaAction<AttachDokumentActionPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const filters: ApiPageParams = yield select(getDokumentsFiltersFromStore)
    const apiPayload = generateAPIPayload(payload, filters)
    yield call(api.attachDokuments, companyId, apiPayload, data)
    yield put(actions.attachDokuments.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

function* attachDokumentFromDetailsSaga({
  payload: { data, ...payload },
  meta: { resolve, reject },
}: AsyncSagaAction<AttachDokumentActionPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const filters: ApiPageParams = yield select(getDokumentsFiltersFromStore)
    const apiPayload = generateAPIPayload(payload, filters)
    yield call(api.attachDokuments, companyId, apiPayload, data)
    const response: AxiosResponse<BackendDokumentDetailResult> = yield call(
      api.fetchDokumentDetails,
      companyId,
      payload.selected[0]
    )
    yield put(actions.fetchDokumentDetails.success(response.data))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

function* attachDokumentsToDetailsSaga({
  payload: { data, ...payload },
  meta: { resolve, reject },
}: AsyncSagaAction<AttachDokumentActionPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const apiPayload = generateAPIPayload(payload, null)
    yield call(api.attachDokuments, companyId, apiPayload, data)
    const response: AxiosResponse<BackendAttachmentFileData[]> = yield call(fetchAttachmentsApi, companyId, {
      documentId: data.target_ids[0],
      documentType: data.target_type,
    })
    yield put(
      attachmentActions.updateAttachmentCount.request({ documentType: data.target_type, count: response.data.length })
    )
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.attachDokuments.REQUEST, attachDokumentsSaga)
  yield takeLatest(actions.attachDokumentFromDetails.REQUEST, attachDokumentFromDetailsSaga)
  yield takeLatest(actions.attachDokumentsToDetails.REQUEST, attachDokumentsToDetailsSaga)
}
