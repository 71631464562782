import React from 'react'

import ContentWithLoadingOverlay from '@components/ui/ContentWithLoadingOverlay'
import { Typography } from '@components/ui/Typography'

import { Loader } from './Loader'

import { ApiErrorMessage, DataLoadingMessage } from '@messages'
import { AccordionContent } from './styles'

interface AsyncAccordionContentProps<Data extends object> {
  children: (payload: Data) => JSX.Element
  data: Nullable<Data>
  error?: BackendError
  isExpanded?: boolean
  isLoading: boolean
}

export function AsyncAccordionContent<Data extends object>({
  children,
  data,
  error,
  isExpanded,
  isLoading,
}: AsyncAccordionContentProps<Data>) {
  if (error) {
    return (
      <AccordionContent>
        <Typography color="error" size="400-sm">
          {error}
        </Typography>
      </AccordionContent>
    )
  }

  if (isLoading && isExpanded && !data) {
    return (
      <AccordionContent>
        <Loader infoText={DataLoadingMessage} />
      </AccordionContent>
    )
  }

  // by here if we do not have data or error something is wrong
  if (!data) {
    return (
      <AccordionContent>
        <Typography color="error" size="400-sm">
          {ApiErrorMessage}
        </Typography>
      </AccordionContent>
    )
  }
  return (
    <AccordionContent>
      <ContentWithLoadingOverlay loading={isLoading}>{children(data)}</ContentWithLoadingOverlay>
    </AccordionContent>
  )
}
