import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  companyHasIntegrationType,
  isAdvancedAccountingAvailableForUser,
  isVisibleForAccountantOrAdmin,
  userHasEditorPermission,
} from '@helpers'

import { AccountingButtonVisibilityControl, FeatureDeniedForUser } from '@oldComponents/VisibilityControls'

import { CompanyIntegrationProviders, QuickUserFeaturePermissions } from '@constants'

const WrapperDiv = styled.div`
  /* NOTE: due to Safari issues with flexbox and absolute positioning, this component can't use flexbox (2022.08.23) */
  /* it needs to be used with first-child due to tooltips being rendered after each div, which would break last-child */
  & > *:not(:first-child) {
    margin-left: 5px;
  }
`

interface DashboardActionsSorterProps {
  accounting?: React.ReactNode
  accountingExport?: React.ReactNode
  aiRecognition?: React.ReactNode
  approve?: React.ReactNode
  attachDokuments?: React.ReactNode
  categorization?: React.ReactNode
  className?: string
  deleting?: React.ReactNode
  download?: React.ReactNode
  exporting?: React.ReactNode
  expressPayment?: React.ReactNode
  filing?: React.ReactNode
  hasIntegrationAcounto: boolean
  hasIntegrationSzamlazzhu: boolean
  isAccountingExportVisibleForUser: boolean
  isAdvancedAccounting: boolean
  isEditorUser: boolean
  manualPayment?: React.ReactNode
  syncingAcounto?: React.ReactNode
  syncingSzamlazzhu?: React.ReactNode
  tagging?: React.ReactNode
}

function PureDashboardActionsSorter({
  accounting,
  accountingExport,
  aiRecognition,
  approve,
  attachDokuments,
  categorization,
  className,
  deleting,
  download,
  exporting,
  expressPayment,
  filing,
  hasIntegrationAcounto,
  hasIntegrationSzamlazzhu,
  isAccountingExportVisibleForUser,
  isAdvancedAccounting,
  isEditorUser,
  manualPayment,
  syncingAcounto,
  syncingSzamlazzhu,
  tagging,
}: DashboardActionsSorterProps) {
  return (
    <WrapperDiv className={className}>
      {/* trigger approve status */}
      {approve}
      {isEditorUser && (
        <>
          {/* file invoices */}
          {filing}
          {/* set accounting status of invoices */}
          {accounting && !isAdvancedAccounting && (
            <AccountingButtonVisibilityControl>{accounting}</AccountingButtonVisibilityControl>
          )}
          {/* account image transfer to Számlaverzum */}
          {hasIntegrationSzamlazzhu && syncingSzamlazzhu}
          {hasIntegrationAcounto && syncingAcounto}
          {/* delete invoices */}
          {deleting && (
            <FeatureDeniedForUser permission={QuickUserFeaturePermissions.denyDeletePermission}>
              {deleting}
            </FeatureDeniedForUser>
          )}
          {/* categorisation of invoices */}
          {categorization}
          {/* tagging of invoices */}
          {tagging && (
            <FeatureDeniedForUser permission={QuickUserFeaturePermissions.denyTagPermission}>
              {tagging}
            </FeatureDeniedForUser>
          )}
        </>
      )}

      {/* everybody can see these */}
      {/* download invoice images */}
      {download}
      {/* dokuments bulk ai recognition */}
      {isEditorUser && aiRecognition}
      {/* export invoice data */}
      {exporting}

      {isAdvancedAccounting && isAccountingExportVisibleForUser && accountingExport}

      {isEditorUser && (
        <>
          {/* create payment package */}
          {manualPayment}
          {/* create express payment package */}
          {expressPayment}
          {/* attach dokuments */}
          {attachDokuments}
        </>
      )}
    </WrapperDiv>
  )
}

PureDashboardActionsSorter.propTypes = {
  accounting: PropTypes.node,
  accountingExport: PropTypes.node,
  aiRecognition: PropTypes.node,
  approve: PropTypes.node,
  attachDokuments: PropTypes.node,
  categorization: PropTypes.node,
  className: PropTypes.string,
  deleting: PropTypes.node,
  download: PropTypes.node,
  exporting: PropTypes.node,
  expressPayment: PropTypes.node,
  filing: PropTypes.node,
  hasIntegrationAcounto: PropTypes.bool.isRequired,
  hasIntegrationSzamlazzhu: PropTypes.bool.isRequired,
  isAccountingExportVisibleForUser: PropTypes.bool.isRequired,
  isAdvancedAccounting: PropTypes.bool.isRequired,
  isEditorUser: PropTypes.bool.isRequired,
  manualPayment: PropTypes.node,
  syncingAcounto: PropTypes.node,
  syncingSzamlazzhu: PropTypes.node,
  tagging: PropTypes.node,
}

export const DashboardActionsSorter = connect((state: Store) => ({
  hasIntegrationAcounto: companyHasIntegrationType(state, CompanyIntegrationProviders.acounto),
  hasIntegrationSzamlazzhu: companyHasIntegrationType(state, CompanyIntegrationProviders.szamlazz),
  isAccountingExportVisibleForUser: isVisibleForAccountantOrAdmin(state.auth.company.data),
  isAdvancedAccounting: isAdvancedAccountingAvailableForUser(state),
  isEditorUser: userHasEditorPermission(state.auth.company.data.role),
}))(PureDashboardActionsSorter)

DashboardActionsSorter.displayName = 'DashboardActionsSorter'
